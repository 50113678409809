@import '../../../../assets/scss/variables';

.add-card-form {
  display: flex;
  flex-direction: column;
  max-width: 870px;
  padding-bottom: 22px;
}

.add-card-form__actions {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  margin-top: 23px;
}

.add-card-form__cancel {
  margin-right: 12px;
  background-color: transparent;
  color: $black;
  border-color: $black;
}

.add-card-form__submit {
  min-width: 150px;
  height: 40px;
  font-size: 14px;
  font-weight: 400;
}
