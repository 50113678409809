@import '../../../assets/scss/variables';

.project-client {
  display: flex;
  align-items: center;

  .project-client__clear-btn {
    padding: 0 8px;
    border: none;
    background: none;
    outline: none;
    display: none;

    & > svg {
      width: 16px;
      height: 16px;
    }
  }

  .project-client__members {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;

    &:hover > .project-client__clear-btn {
      display: block;
    }

    @media (hover: none) {
      .project-client__clear-btn {
        display: none;
      }
    }
  }
}
