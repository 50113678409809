@import 'assets/scss/variables';

.payment-details {
  max-width: none;
  padding-top: 30px;
  padding-bottom: 7px;
}

.payment-details__title {
  margin-bottom: 26px;
  margin-left: -1px;
}

.payment-details__subtitle {
  font-size: 22px;
  font-weight: 300;
  line-height: 30px;
  margin-bottom: 25px;
}

.payment-details__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  > .ua-input {
    width: 48%;
    margin-bottom: 22px;
  }

  > .ua-checkbox {
    width: 48%;
    margin-top: 5px;
    align-self: center;
  }

  > .payment-details__row {
    width: 48%;
  }
}

.payment-details__row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  > div {
    width: calc(50% - 5px);
  }
}

.payment-details__checkbox {
  padding-left: 1px;

  .ua-checkbox__label {
    font-size: 14px;

    span {
      padding-left: 2.5px;
    }
  }

  .ua-checkbox__icon {
    margin-top: -4px;
  }
}

@media (max-width: 1100px) {
  .payment-details {
   padding-bottom: 25px;
  }

  .payment-details__container {
    display: block;

    > .ua-input {
      width: 100%;
    }

    > .ua-checkbox {
      width: 100%;
    }

    > .payment-details__row {
      width: 100%;
    }
  }
}

@media (max-width: 500px) {
  .payment-details__row {
    display: block;

    > div {
      width: 100%;
    }
  }
}
