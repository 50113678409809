.auth-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  padding-top: 70px;
  padding-bottom: 70px;
  padding-left: 15px;
  padding-right: 15px;
  background-image: url('/assets/images/background.jpg');
  background-color: rgba(26, 59, 131, 0.8);
  background-size: cover;
  background-repeat: no-repeat;
  overflow-y: auto;

  .alert {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-size: 14px;
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    padding: 6px;
    max-width: 380px;

    @media (max-width: 575.98px) {
      top: 44px;
      font-size: 12px;
      z-index: 1;
    }
  }
}

.auth-layout__logo-wrapper {
  margin-bottom: 30px;
}

@media (max-width: 575.98px) {
  .auth-layout {
    padding-top: 40px;
    padding-bottom: 40px;

    .auth-layout__logo-wrapper {
      margin-bottom: 45px;
    }
  }
}

.registration-page {
  @media (max-width: 425px) {
    &.auth-layout {
      height: auto;
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
    }

    .auth-layout__logo-wrapper {
      max-width: 150px;
      margin-top: 6px;
      margin-bottom: 0;

      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
  }
}
