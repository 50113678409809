@import 'assets/scss/variables';

.support-form {
  padding: 0;
  box-shadow: none;
  width: 100%;
  max-width: 620px;

  .form__controls-group {
    flex: 1;
  }

  .support-form__space {
    margin-bottom: 10px;

    & ~ .support-form__space {
      margin-bottom: 7px;
    }
  }

  @media (max-width: 575.98px) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.support-form__submit-button {
  min-width: 120px;
  height: 40px;
  margin-left: auto;
  margin-top: 33px;
}

.support-form__attachment {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;
  margin-left: 12px;
  margin-top: 3px;
}

.support-form__attachment-label {
  color: $gray;
  font-size: 12px;
  margin-left: 12px;
}

.support-form__attachment-input {
  opacity: 0;
  position: absolute;
  height: 0;
  width: 0;
}

.support-form__attachment-list {
  display: flex;
  flex-direction: column;
  margin: 12px 0 0;
}

.support-form__attachment-file {
  color: $gray;
  font-size: 12px;
}

.support-form__attachment-btn {
  border: none;
  background: none;
  padding: 0;
  margin-left: 12px;
}
