.section {
  .section__title {
    font-size: 22px;
    line-height: 30px;
    font-weight: 300;
    color: $dark-gray;
    margin-bottom: 40px;
  }
}

.section-content {
  margin-bottom: 50px;

  .content-title {
    font-size: 16px;
    line-height: 17px;
    color: $dark-gray;
    font-weight: normal;
    margin-bottom: 3px;
  }

  &:last-child {
    margin-bottom: 0;
  }
}
