@import '../../../assets/scss/variables';

.aside-container {
  flex: 0;
  max-width: 0;
  margin-left: 0;
  overflow: hidden;
  transition: all 0.33s ease;
  position: sticky;
  top: 80px;

  @media (max-width: 767.98px) {
    transition: none;
    &.aside-container--hidden {
      display: none;
    }
  }
}

.aside-container__content {
  border: 1px solid $light-gray;
  border-radius: 4px;
  background-color: $white;
  width: 400px;
  overflow-y: auto;
  max-height: calc(100vh - 80px);
}

.aside-container__close-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin-left: auto;
  border: none;
  background-color: transparent;
  transition: background-color 0.33s ease;
  border-radius: 4px;

  &:hover {
    background-color: $light-gray;
  }
}
