.boards-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.boards-list__item {
  padding: 10px 0;
  outline: none;

  &:last-child {
    margin-bottom: 0;
  }
}

@media (max-width: 767.98px) {
  .boards-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
  }

  .boards-list__item {
    padding: 10px 10px;
    max-width: 100%;
    flex: 0 0 100%;
  }
}
