@import 'src/assets/scss/variables';

.manage-users__header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 25px;
}

.manage-users__header-right {
  display: flex;
  align-items: center;
}

.manage-users__mobile-row {
  display: none;
}

.manage-users__title {
  font-size: 28px;
  line-height: 30px;
  font-weight: 300;
  color: $dark-gray;
  margin-bottom: 0;
  margin-top: 10px;
}

.manage-users__account-seats {
  display: inline-block;
  font-size: 16px;
  line-height: 17px;
  font-weight: 300;
  margin-right: 1rem;
}

.manage-users__table-container {
  overflow-x: auto;
}

.manage-users__table {
  width: 100%;

  .col-label {
    font-size: 12px;
    line-height: 17px;
    color: $gray;
    font-weight: 300;
    margin-right: 5px;
  }

  .col-actions {
    width: 100px;
  }

  .col-user {
    width: 39.7%;
  }

  .col-access {
    width: 26.6%;
  }

  th,
  td {
    padding-right: 25px;
    min-width: 170px;
  }

  thead {
    th {
      padding-bottom: 10px;
      cursor: pointer;

      .col-sort-arrow {
        &.col-sort-arrow__active {
          color: $blue;
        }

        &.col-sort-arrow__rotate {
          transform: rotate(180deg);
        }
      }
    }
  }

  tbody {
    tr {
      border-top: 1px solid $light-gray;
    }

    td {
      font-size: 14px;
      line-height: 21px;
      padding: 19px 25px 12px 0;
      white-space: nowrap;

      &.col-role,
      &.col-date,
      &.col-actions {
        padding-top: 9px;
      }

      &.col-actions {
        text-align: right;
      }
    }
  }

  .user-email {
    margin-bottom: 0;
    color: $gray;
  }

  .user {
    display: flex;
    align-items: center;

    .user-info {
      max-width: 300px;
    }

    .user-name,
    .user-email {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 100%;
    }
  }

  .user-avatar {
    margin-right: 20px;
  }
}

.manage-users__resend-password {
  border: 1px solid $gray;
  border-radius: 4px;
  background-color: transparent;
  padding: 6px 8px;
  margin-right: 12px;
  font-size: 10px;
  line-height: 1.5;
  color: $dark-gray;

  &:focus {
    box-shadow: none;
  }
}

.manage-users__action-button {
  border: none;
  background-color: transparent;
  padding: 6px;
  margin-right: 15px;

  &.manage-users__action-button--delete {
    color: $danger;

    path {
      fill: currentColor;
    }
  }

  svg {
    width: 20px;
    height: 20px;
  }
}

.manage-users__mobile-info {
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px;
  margin-right: -20px;
  height: 103px;
  transition: max-height 0.33s ease-in;
  max-height: 0;
  overflow: hidden;
}

@media (min-width: 768px) {
  .collapse-button {
    display: none;
  }
}

@media (max-width: 1199px) {
  .manage-users__table {
    .col-access,
    .col-actions,
    .col-user {
      width: auto;
    }
  }
}

@media (max-width: 767.98px) {
  .manage-users__header {
    margin-bottom: 12px;
  }

  .manage-users__table-container {
    overflow: hidden;
  }

  .manage-users__table {
    thead {
      display: none;
    }

    .user-avatar {
      width: 40px !important;
      height: 40px !important;
    }

    .user-info {
      max-width: calc(100% - 100px);
    }

    .user-name,
    .user-email {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .collapse-button {
      margin-left: auto;
      background-color: transparent;
      border: none;
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;

      svg {
        width: 100%;
        height: 100%;
        transition: transform 0.33s ease-in;
      }

      &.collapse-button--open {
        svg {
          transform: rotate(180deg);
        }
      }
    }

    tbody {
      td {
        padding-top: 15px;
        padding-bottom: 15px;
        padding-right: 0;
      }

      .manage-users__mobile-row {
        border-top: none;
        display: table-row;
        td {
          padding: 0;
          transition: padding 0.33s ease-in;
        }

        &.manage-users__mobile-row--open {
          td {
            padding-top: 8px;
            padding-bottom: 23px;
          }

          .manage-users__mobile-info {
            max-height: 103px;
            width: calc(100vw - 70px);
          }
        }
      }
    }

    .col-access,
    .col-actions,
    .col-date,
    .col-role {
      display: none;
    }

    .user-info-col__data {
      margin-bottom: 0;
      font-size: 14px;
      line-height: 21px;
      color: $dark-gray;

      .ua-dropdown {
        .dropdown-toggle {
          padding: 0;
          font-size: 14px;
          line-height: 21px;
          color: $dark-gray;
        }
      }
    }

    .user-info-col__title {
      font-size: 12px;
      line-height: 17px;
      color: $gray;
      margin-bottom: 0;
    }

    .user-info-col {
      flex: 0 0 50%;
      max-width: 50%;
      padding-left: 20px;
      padding-right: 20px;

      &.user-info-col--actions {
        flex: auto;
        width: 100%;
        max-width: 100%;
        margin-top: 27px;
        display: flex;
        flex-wrap: wrap;
      }

      .manage-users__resend-password,
      .manage-users__delete-user {
        border: 1px solid;
        border-radius: 4px;
        color: $gray;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        background-color: transparent;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .manage-users__resend-password {
        border-color: $gray;
        color: $gray;
        width: 152px;
        flex: 0 0 152px;
        height: 36px;
        margin-right: 16px;
      }

      .manage-users__delete-user {
        border-color: $danger;
        color: $danger;
        width: 128px;
        flex: 0 0 128px;
        height: 36px;
      }
    }
  }
}

@media (max-width: 425px) {
  .manage-users__table tbody .manage-users__mobile-row.manage-users__mobile-row--open .manage-users__mobile-info {
    height: 153px;
    max-height: 153px;

    & .manage-users__delete-user {
      margin-top: 12px;
    }
  }
}
