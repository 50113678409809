@import '../../../assets/scss/variables';

.project-team {
  display: flex;
  align-items: center;

  .project-team__editable {
    cursor: pointer;
  }

  .project-team__members {
    display: flex;
    align-items: center;
    position: relative;
  }

  .project-team__add-button {
    display: flex;
    padding: 0;
    border: none;
    border-radius: 4px;
    color: $gray;
    background-color: transparent;

    svg {
      width: 30px;
      height: 30px;

      path {
        fill: currentColor;
      }
    }
  }

  .project-team__members-item {
    width: 30px;
    height: 30px;
    border: 2px solid $white;
    margin-left: -10px;
    position: relative;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 10px;
    }
  }
}
