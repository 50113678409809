.recent-payments {
  padding-top: 22.5px;
}

.recent-payments__title {
  margin-bottom: 36px;
}

.recent-payments--edit-billing {
  padding-top: 17.5px;
}
