@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

// colors
$blue: #447df7;
$gray: #888;
$green: #87cb16;
$yellow: #ff9500;
$red: #fb404b;
$dark-blue: #1a3b83;
$dark-gray: #333;
$light-gray: #e7e7e9;
$light-blue: #23ccef;
$black: #212529;

$primary: $blue;
$secondary: $gray;
$success: $green;
$warning: $yellow;
$danger: $red;
$info: $light-blue;

// fonts
$font-family-sans-serif: 'Roboto', sans-serif;

// buttons
$btn-padding-x: 18px;
$btn-padding-y: 11px;
$btn-font-family: $font-family-sans-serif;
$btn-font-size: 14px;
$btn-line-height: 16px;
$btn-border-radius: 4px;

// dashboard
$bg-blue: #f2f4f9;
$dashboard-background: $bg-blue;

// bootstrap variables
$font-family-base: $font-family-sans-serif;
