@import 'assets/scss/variables';

.cards-table {
  color: $gray;
  overflow-x: auto;

  table {
    width: 100%;

    tbody {
      tr {
        border-bottom: 1px solid $light-gray;

        &:last-child {
          border: none;
        }
      }

      td {
        font-size: 14px;
        line-height: 21px;
        padding: 34px 0 10.6px;
        white-space: nowrap;

        &.col-card-icon {
          padding-top: 32.4px;
          padding-right: 20px;
          width: 20px;
        }

        &.col-card-number {
          min-width: 120px;
          width: 62%;
        }

        &.col-card-status {
          width: 27%;
          min-width: 150px;
        }
      }
    }
  }
}

.card-table__active-card,
.cards-table__switch-card {
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    margin-right: 11.5px;
  }
}

.card-table__active-card {
  color: $green;
}

.cards-table__switch-card {
  color: $gray;
  background: none;
  border: none;
  margin: 0 auto;
  padding-left: 10px;
}

.card-table__check-icon {
  width: 18px;
  height: 18px;

  path {
    fill: $green;
  }
}

.card-table__update-cards {
  border: 1px solid $gray;
  border-radius: 4px;
  background-color: transparent;
  padding: 6.3px 8px;
  font-size: 10px;
  line-height: 1.5;
  color: $dark-gray;
  margin-right: 14px;

  &:focus {
    box-shadow: none;
  }
}

.card-table__action-button {
  border: none;
  background-color: transparent;
  padding: 6px;
  margin-right: 14px;

  &.card-table__action-button {
    color: $danger;

    path {
      fill: currentColor;
    }
  }

  svg {
    width: 20px;
    height: 20px;
  }
}
