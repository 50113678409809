@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins/ua-scrollbar';

.kanban-container {
  @include ua-scrollbar;

  display: flex;
  overflow-x: auto;
  padding: 0 15px 20px;
}

.kanban-container-loader {
  width: 100%;
  text-align: center;

  .spinner-border {
    border-color: $gray;
    border-right-color: transparent;
  }
}
