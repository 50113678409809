@import 'src/assets/scss/variables';

.board-item {
  padding: 15px 20px 15px 12px;
  background-color: $white;
  border: 1px solid $light-gray;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.board-item__drag {
  color: $gray;
}

.board-item__description {
  margin-left: 68px;
  width: 230px;
  position: relative;
  padding-right: 22px;
}

.board-item__description-name a {
  display: block;
  align-items: center;
  font-size: 12px;
  line-height: 13px;
  font-weight: bold;
  color: $dark-gray;
  margin-bottom: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 185px;
  text-decoration: none;
}

.board-item__starred-button {
  background: none;
  border: none;
  display: flex;
  align-items: center;
  color: $gray;
  margin-left: 11px;
  margin-top: -2px;
  position: absolute;
  top: 1px;
  right: 0;

  svg {
    path {
      fill: currentColor;
    }
  }

  &.board-item__starred-button--active {
    color: $warning;
  }
}

.board-item__updated {
  margin-left: 30px;
}

.board-item__description-projects,
.board-item__updated-at {
  display: block;
  font-size: 10px;
  line-height: 14px;
  color: $gray;
}

.board-item__updated-label {
  font-size: 10px;
  line-height: 14px;
  color: $dark-gray;
  margin-bottom: 0;
}

.board-item__projects-counters {
  margin-left: auto;
  display: flex;
  margin-right: 10px;
}

.board-item__projects {
  display: flex;
  align-items: center;
  margin-right: 24px;
  font-size: 12px;
  line-height: 17px;
  flex-direction: column;

  svg {
    margin-bottom: 6px;
  }

  &.board-item__projects--active {
    color: $primary;
  }

  &.board-item__projects--completed {
    color: $success;
  }

  &.board-item__projects--due {
    color: $warning;
  }

  &.board-item__projects--overdue {
    color: $danger;
  }
}

.board-item__menu {
  width: 20px;
  background-color: transparent;
  border: none;

  .dropdown-toggle {
    padding: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .dropdown-menu {
    min-width: 90px;
    width: auto;

    .dropdown-item {
      padding: 4px 12px;
    }
  }
}

@media (max-width: 767.98px) {
  .board-item {
    flex-wrap: wrap;
    padding: 15px 10px;

    .board-item__description {
      margin-left: 12px;
    }

    .board-item__updated {
      margin-left: auto;
    }

    .board-item__projects-counters {
      margin-left: auto;
      margin-right: 0;
    }

    .board-item__menu {
      margin-left: 10px;
    }
  }
}

@media (max-width: 600px) {
  .board-item {
    .board-item__projects-counters {
      margin-top: 20px;
      min-width: 75%;
      margin-left: 32px;
    }

    .board-item__menu {
      margin-top: 20px;
      margin-left: auto;
    }
  }
}

@media (max-width: 575.98px) {
  .board-item {
    .board-item__drag {
      display: none;
    }

    .board-item__description {
      margin-left: 0;
    }

    .board-item__projects-counters {
      margin-left: 0;
    }
  }
}
