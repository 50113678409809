@import 'src/assets/scss/variables';

.tag-list {
  width: calc(100% - 16px);
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &__dropdown {
    .btn {
      padding: 0 10px;
    }

    .dropdown-menu {
      padding: 12px 15px;
      max-height: 40vh;
      overflow-y: scroll;

      .tag-list-item {
        padding: 0 0 4px;
        display: flex;
        align-items: center;
      }

      .tag {
        width: 242px;
        height: 35px;
        padding: 2px 16px;
        display: flex;
        align-items: center;
        margin-right: 15px;
        cursor: pointer;
        font-size: 16px;
      }

      .tag-list-add-button {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $gray;
        font-size: 14px;
        line-height: 15px;
        padding: 12px 24px;
        background: none;
        border: none;
        width: 100%;

        svg {
          width: 12px;
          height: 12px;
          margin-right: 9px;
        }
      }

      .tag-list-item__button {
        display: flex;
        align-items: center;
        padding: 0;
        background: none;
        border: none;
        margin-right: 10px;

        &:last-child {
          margin-right: 0;
        }

        svg {
          margin-right: 0;
        }
      }
    }
  }
}

.tag-list-form {
 width: 200px;

  .ua-input__control {
    height: 25px;
    padding-top: 0;
    padding-bottom: 0;
  }
}
