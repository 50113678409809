@import 'assets/scss/variables';

.profile-dropdown {
  .dropdown-toggle {
    padding: 5px 13px;
    background-color: transparent !important;
    border-color: transparent !important;

    &:not(.disabled):active {
      background-color: transparent !important;
      border-color: transparent !important;
    }
  }

  .profile-dropdown__avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;
  }

  .profile-dropdown__name {
    margin-bottom: 0;
  }

  .dropdown-menu {
    @media (min-width: 768px) {
      width: 250px;
    }

    &.link-logout {
      color: $danger;
    }
  }

  .profile-dropdown__account-accordion {
    padding: 8px 24px;
    border: none;
    background: none;

    .arrow-icon {
      margin-left: 36px;
      margin-right: 0;
      transition: transform 0.2s;

      &.expanded {
        transform: rotate(180deg);
      }
    }

    svg {
      margin-right: 8px;
    }
  }

  .account-switcher__name {
    margin-bottom: 0;
    margin-left: 10px;
  }

  #expanded-account-list > * {
    padding-left: 32px;
  }

  #expanded-account-list > .selected-account {
    background-color: #f2f4f9;

    & .account-switcher__name {
      color: #447df7;
    }
  }
}
