@import '../../../assets/scss/variables';

.ua-dropdown {
  position: relative;

  &.show > .btn-light.dropdown-toggle {
    background: none;
  }

  .dropdown-toggle {
    display: flex;
    align-items: center;
    background-color: transparent;
    border-color: transparent;

    &:focus,
    &:hover,
    &:active,
    &:active:focus {
      box-shadow: none !important;
      outline: none !important;
      border-color: transparent !important;
      background-color: transparent !important;
    }

    .ua-dropdown__caret {
      margin-left: 18px;

      path {
        fill: currentColor;
      }
    }

    &::after {
      display: none;
    }

    &::before {
      display: none;
    }

    .ua-dropdown__button-content {
      display: flex;
      align-items: center;
    }
  }

  .dropdown-menu {
    padding: 5px 0;

    .dropdown-item {
      display: flex;
      align-items: center;
      padding: 8px 24px;
      background: none;
      color: #212529;

      svg {
        margin-right: 8px;
      }
    }
  }

  .dropdown-divider {
    margin-left: auto;
    margin-right: auto;
    max-width: 86%;
  }

  &.ua-dropdown--dark {
    .dropdown-menu {
      background-color: $dark-gray;
      opacity: 0.75;
      border-radius: 4px;

      .dropdown-item {
        font-size: 14px;
        line-height: 21px;
        color: $white;

        svg {
          margin-right: 6px;

          path {
            fill: currentColor;
          }
        }

        &:hover,
        &:active {
          background-color: rgba(255, 255, 255, 0.25);
        }
      }
    }
  }
}
