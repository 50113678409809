@import '../../assets/scss/variables';

.modal-container {
  &.ua-modal {
    max-width: 660px;
    justify-content: center;
  }

  .modal-header {
    padding: 0;
  }

  .ua-modal__close-btn {
    svg {
      path {
        fill: $dark-gray;
      }
    }
  }
}

@media (max-width: 670px) {
  .modal-container {
    &.ua-modal {
      margin: 0.5rem;
    }
  }
}
