@import '../../../assets/scss/variables';

.edit-profile {
  display: flex;
}

.edit-profile__avatar {
  width: 210px;
  max-height: 220px;
  padding: 28px 20px 20px;
  border: 1px solid $light-gray;
  border-radius: 4px;
  flex: 0 0 210px;
  position: relative;
}

.edit-profile__avatar-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 28px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: fit-content;

  .user-avatar {
    border-radius: 50%;
    border: 4px solid rgba($primary, 0.3);
    background-color: rgba($primary, 0.3);
  }
}

.edit-profile__avatar-delete {
  position: absolute;
  top: 0;
  right: 0;

  background: white;
  border-radius: 50%;
  border: 0.5px solid #e7e7e9;
  color: $gray;
  padding: 0;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: $blue;
    border-color: $blue;
    color: white;
  }
}

.edit-profile__avatar-button {
  width: 100%;
  padding: 10px 18px;
  border: 2px solid $primary;
  border-radius: 4px;
  color: $primary;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  cursor: pointer;

  input {
    display: none;
  }

  .edit-profile__avatar-button-icon {
    margin-right: 16px;
  }
}

.edit-profile__form-container {
  width: 100%;
  margin-left: 20px;
  padding: 0 10px;
}

.edit-profile__form {
  width: 100%;
}

.edit-profile__form-caption,
.edit-profile__title {
  font-size: 28px;
  line-height: 30px;
  color: $dark-gray;
  margin-bottom: 32px;
  font-weight: 300;
}

.edit-profile__title {
  display: none;
}

.edit-profile__form-row {
  display: flex;
  margin-left: -10px;
  margin-right: -10px;
}

.edit-profile__form-col {
  flex: 1;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 22px;

  .ua-input {
    margin-bottom: 0;
  }

  &.edit-profile__form-col--requirements {
    display: flex;
    align-items: flex-end;
  }
}

.edit-profile__form-action {
  text-align: right;
  width: 100%;
}

@media (max-width: 1343px) {
  .sidebar--closed {
    + .layout__content {
      .edit-profile__form {
        display: flex;
        margin-left: -10px;
        margin-right: -10px;
      }

      .edit-profile__form-row {
        flex: 1;
        margin: 0;
        padding-left: 10px;
        padding-right: 10px;
        flex-direction: column;
      }

      .edit-profile__form-col {
        padding-left: 0;
        padding-right: 0;

        &.edit-profile__form-col {
          align-items: flex-start;
        }
      }
    }
  }
}

@media (max-width: 1533px) {
  .edit-profile__form {
    display: flex;
    margin-left: -10px;
    margin-right: -10px;
    flex-wrap: wrap;
  }

  .edit-profile__form-row {
    flex: 1;
    margin: 0;
    padding-left: 10px;
    padding-right: 10px;
    flex-direction: column;
    min-width: 100%;
  }

  .edit-profile__form-col {
    padding-left: 0;
    padding-right: 0;

    &.edit-profile__form-col {
      align-items: flex-start;
    }
  }
}

@media (max-width: 1240px) {
  .sidebar--closed {
    + .layout__content {
      .edit-profile__form {
        flex-direction: row;
      }
    }
  }

  .edit-profile__form {
    flex-direction: column;
  }
}

@media (max-width: 1040px) {
  .edit-profile__form {
    flex-direction: column !important;
    margin-left: 0;
    margin-right: 0;
  }

  .edit-profile__form-container {
    margin-left: 0;
  }

  .edit-profile__form-row {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (max-width: 655px) {
  .edit-profile__title {
    display: block;
    margin-bottom: 20px;
  }

  .edit-profile__form-caption {
    display: none;
  }

  .edit-profile {
    flex-direction: column;
    align-items: center;
  }

  .edit-profile__avatar {
    margin-bottom: 20px;
  }
}
