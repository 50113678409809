@import '../../../assets/scss/variables';

.ua-modal {
  justify-content: center;

  .modal-content {
    border: none;
  }

  .modal-footer {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    display: flex;
    justify-content: space-between;
    padding: 0 30px 30px;

    &.ua-modal__footer--ok-only {
      justify-content: center;
    }
  }

  .modal-body {
    padding: 0 30px 30px;
  }
  .modal-header {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: 10px 10px 0;
  }

  .modal-header,
  .modal-body,
  .modal-footer {
    border: none;
  }

  &.ua-modal--confirm-dialog {
    &.modal-dialog {
      max-width: 480px;
    }

    .modal-content {
      width: auto;
    }

    .ua-modal__message {
      text-align: center;
      margin-bottom: 0;
      margin-top: 16px;
    }

    .modal-footer {
      justify-content: center;
    }
  }

  &.ua-modal--no-footer {
    .modal-body {
      padding-bottom: 15px;
    }
  }
}

.modal-dialog {
  max-width: 360px;
  margin-right: auto;
  margin-left: auto;
}

.ua-modal__close-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin-left: auto;
  border: none;
  background-color: transparent;
  transition: background-color 0.33s ease;
  border-radius: 4px;

  &:hover {
    background-color: $light-gray;
  }
}

.ua-modal__title {
  font-size: 28px;
  line-height: 30px;
  font-weight: 300;
  text-align: center;
  color: $dark-gray;
  margin-bottom: 26px;
}

.ua-modal__footer-button {
  width: 140px;
  height: 40px;
  margin: 0;

  &.close-button {
    border-width: 2px;
  }
}

@media (max-width: 374.98px) {
  .ua-modal__title {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 20px;
  }

  .ua-modal__footer-button {
    width: 100px;
    margin-left: 5px;
    margin-right: 5px;
  }

  .ua-modal {
    .modal-footer {
      justify-content: center;
    }

    .modal-body {
      padding: 0 15px 15px;
    }
  }
}
