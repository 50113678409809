@import 'src/assets/scss/mixins/ua-scrollbar';
@import 'src/assets/scss/variables';

.board-page {
  position: relative;

  .page-header {
    display: flex;
    flex-wrap: wrap;
    row-gap: 12px;

    .page-header__col {
      padding-bottom: 5px;
    }

    .project-team {
      margin-top: 30px;
    }

    .ua-editable-field--editing {
      margin-bottom: -9px;
    }
  }

  .cards {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    .card-container {
      flex-basis: 240px;
      flex-shrink: 0;
      flex-grow: 1;
    }
  }

  .page-content {
    margin-top: 16px;
    display: flex;
    align-items: flex-start;
    transition: all 0.33s ease;
  }

  .toolbar-container {
    display: flex;
    flex-wrap: wrap;
    column-gap: 30px;
    row-gap: 15px;
    padding: 15px 15px 25px;
    justify-content: flex-end;
    background: $white;
    position: sticky;
    top: 80px;
    z-index: 2;

    .toolbar-col {
      flex: 1 1;
      display: flex;
      justify-content: flex-start;
    }

    .toolbar-col__kanban {
      flex: 0;
    }
  }
}

.board-page,
.ua-bottom-menu {
  .new-project-btn {
    width: 40px;
    height: 40px;
    border: none;
    background-color: $primary;
    transition: background-color 0.15s ease;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;

    & svg {
      width: 16px;
      height: 16px;

      path {
        fill: currentColor;
      }

      &.stroke {
        path {
          stroke: currentColor;
        }
      }
    }

    &:hover {
      background-color: darken($primary, 5%);
    }
  }
}

.page-content--aside-open {
  .projects-table-container,
  .projects-kanban-container {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(100% - 420px);
    max-width: calc(100% - 420px);
  }

  .aside-container {
    flex: 0 0 400px;
    max-width: 400px;
    margin: 0 0 0 20px;
  }
}

.kanban-create-button {
  display: flex;
  justify-content: center;
  width: 100%;
  border: 2px dashed $primary;
  background: $bg-blue;
  border-radius: 4px;
  padding: 15px;

  .button-label {
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 17px;
    color: $primary;

    svg {
      width: 25px;
      height: 25px;
      margin-right: 10px;
    }
  }
}

.ua-bottom-menu {
  .toolbar {
    &:last-child {
      padding-right: 16px;
    }
  }
}

@media (max-width: 1330px) {
  .cards {
    width: 100%;
  }
}

@media (min-width: 1025px) {
  .toolbar-mobile {
    display: none;
  }
}

@media (max-width: 1700px) {
  .board-page {
    .page-content {
      .toolbar-container {
        margin-left: 0;
        margin-right: 0;

        .toolbar-search {
          min-width: auto;
        }
      }

      .toolbar-col {
        @include ua-scrollbar;

        justify-content: flex-start;
        flex-basis: 100%;
        padding-left: 0;
        padding-right: 0;
        overflow-x: auto;
      }
    }
  }
}

@media (max-width: 1109.98px) {
  .board-page {
    .toolbar-container {
      margin-left: 0;
      margin-right: 0;

      .toolbar-col {
        flex: 1 1 100%;
        width: 100%;
        padding-left: 0;
        padding-right: 0;
        overflow-x: auto;
      }

      .toolbar,
      .search {
        padding-left: 0;
        padding-right: 0;
      }

      .toolbar {
        padding-bottom: 10px;
      }
    }
  }

  .page {
    .page-content {
      .toolbar-container {
        .toolbar-search {
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 1330px) {
  .board-page {
    .cards {
      .card-container {
        flex-basis: calc(50% - 10px);
      }
    }
  }
}

@media (max-width: 1024px) {
  .board-page {
    .page-content--aside-open {
      .projects-table-container,
      .projects-kanban-container {
        max-width: 100%;
        display: none;
      }
    }

    .page-header {
      row-gap: 4px;
    }

    .toolbar-container {
      position: static;

      & .toolbar {
        padding-bottom: 0;
      }

      & > .toolbar-col--search {
        padding-left: 10px;
        padding-right: 10px;
      }

      & > .toolbar-col--buttons {
        display: none;
      }
    }

    .cards {
      gap: 8px;
    }

    .page-content {
      margin-top: 4px;
      position: relative;
      display: block;
    }

    .aside-container {
      margin: 0 auto;
    }

    .aside-container__content {
      width: 100%;
    }
  }
}
