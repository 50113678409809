.page {
  .page-title {
    font-size: 28px;
    line-height: 30px;
    font-weight: 300;
    color: #000;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 360px;
  }

  .page-header {
    display: flex;
    justify-content: space-between;
  }
}
