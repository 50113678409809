@import 'assets/scss/variables';

.setting-card-item {
  width: 100%;
  margin-bottom: 20px;
}

.setting-card-item__header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: $dark-gray;
}

.setting-card-item__icon {
  margin-right: 10px;

  svg {
    width: 20px;
    height: 20px;

    path {
      fill: currentColor;
    }
  }
}

.setting-card-item__name {
  font-size: 16px;
  line-height: 17px;
  color: $gray;
}

.setting-card-item__body {
  padding-left: 30px;
}
