@import 'src/assets/scss/variables';

.ua-modal.subscription-over-modal {
  max-width: 480px;

  .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    text-align: center;
    font-size: 18px;

    > h3 {
      font-size: 1.5em;
    }

    > .btn {
      margin-top: 24px;
      min-width: 110px;
    }

    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
}
