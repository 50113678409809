@import '../../../assets/scss/variables';

.project-kanban-card {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.project-kanban-card__title {
  display: flex;
  align-items: center;
  color: $dark-gray;
  font-size: 16px;
  line-height: 17px;
  margin-bottom: 8px;
  font-weight: 400;
  cursor: pointer;

  .priority {
    margin-right: 6px;
  }
}

.project-kanban-card__tagline {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;
}

.project-kanban-card__widgets {
  display: flex;
  margin-left: -3px;
  margin-right: -3px;
}

.project-kanban-card__widget-col {
  display: flex;
  align-items: center;
  padding-left: 3px;
  padding-right: 3px;
}

.project-kanban-card__widget {
  color: $gray;
  font-weight: 300;
  font-size: 10px;
  line-height: 14px;

  svg {
    margin-right: 2px;
  }

  .due-date {
    color: $gray;

    svg {
      margin-right: 2px;
    }
  }
}

.project-kanban-card__header {
  display: flex;
  overflow: hidden;
}

.project-kanban-card__header-col {
  flex: 1;

  &.team {
    flex: 0 0 30px;
  }
}
