@import 'assets/scss/variables';

.recent-payments-table {
  overflow-x: auto;

  .col-label {
    font-size: 12px;
    line-height: 17px;
    color: $gray;
    font-weight: 300;
    margin-right: 5px;
  }

  table {
    width: 100%;

    thead {
      th {
        padding-top: 2px;
        padding-bottom: 10px;
      }
    }

    tbody {
      tr {
        border-top: 1px solid $light-gray;

        &.row--dangerous {
          color: $red;

          path {
            fill: $red;
          }
        }
      }

      td {
        font-size: 14px;
        line-height: 21px;
        padding: 17.8px 0 19.8px;
        white-space: nowrap;

        &.col-date {
          width: 20%;
          min-width: 100px;
          padding-top: 17px;
        }

        &.col-reference {
          width: 38%;
          min-width: 180px;
        }

        &.col-price {
          width: 16%;
          min-width: 100px;
          padding-top: 15px;
        }

        &.col-status {
          width: 16%;
          min-width: 100px;
          text-align: center;

          &.paid {
            color: $green;
          }

          &.open {
            color: $yellow;
          }

          &.void {
            color: $gray;
          }
        }

        &.col-action {
          padding-top: 16px;
          text-align: center;
        }
      }
    }
  }
}

.recent-payments-table__action-view,
.recent-payments-table__reload {
  background: none;
  border: none;
}

.recent-payments-table__action-view {
  color: $blue;
}

.recent-payments-table__reload {
  svg {
    width: 24px;
    height: 24px;
  }
}
