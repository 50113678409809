@import 'assets/scss/variables';
@import 'assets/scss/mixins/ua-scrollbar';

.project-filter-autosuggest {
  width: 100%;
  position: relative;

  svg {
    position: absolute;
  }

  .react-autosuggest__suggestions-container {
    @include ua-scrollbar;

    display: block;
    opacity: 0;
    position: absolute;
    top: 45px;
    width: 100%;
    border: none;
    border-radius: 4px;
    z-index: 2;
    max-height: 200px;
    overflow-y: auto;
    background-color: $white;
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.08);
  }

  .react-autosuggest__suggestions-container--open {
    opacity: 1;
  }

  .react-autosuggest__input--focused {
    border: 1px solid $gray;
    outline: none;
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: 0;
  }

  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 6px 10px;
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: $bg-blue;
  }
}

.project-filter-autosuggest__control {
  width: 100%;
  font-size: 14px;
  line-height: 21px;
  color: #333;
  border: 1px solid #e7e7e9;
  border-radius: 4px;
  padding: 8px 13px;
  -webkit-appearance: none;
}

.project-filter {
  .project-filter-selected-items {
    margin-bottom: 8px;

    &.project-filter-selected-items--flex {
      display: flex;
      margin-left: -2px;
      margin-right: -2px;
      flex-wrap: wrap;
    }
  }

  .project-filter-selected-item {
    padding-left: 2px;
    padding-right: 2px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    margin-right: 8px;

    &__delete {
      padding: 0;
      background: none;
      border: none;
      display: flex;
    }
  }

  .project-filter-select__placeholder,
  .project-filter-select__input {
    width: 100%;
    font-size: 14px;
    line-height: 21px;
    color: #333;
    border-radius: 4px;
    padding: 0;
    -webkit-appearance: none;
  }

  .project-filter-select__value-container {
    padding: 4px 8px;
  }

  .project-filter-select__control {
    border-color: $light-gray;
    transition: none;

    &:hover {
      border-color: $light-gray;
    }

    &.project-filter-select__control--is-focused {
      border-color: $gray;
      box-shadow: none;

      &:hover {
        border-color: $gray;
      }
    }
  }

  .project-filter-select__menu-list {
    @include ua-scrollbar;
  }

  .project-filter-select__option {
    display: flex;
    align-items: center;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 21px;
    cursor: pointer;

    &.project-filter-select__option--focused,
    &:hover {
      background-color: $bg-blue;
    }

    .user-avatar {
      margin-right: 10px;
    }

    .user-avatar__name {
      font-size: 12px;
      line-height: 14px;
    }
  }
}
