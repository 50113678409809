@import 'assets/scss/variables';

.ua-textarea {
  width: 100%;

  &.ua-textarea--valid {
    textarea {
      border-color: $success;
    }
  }

  &.ua-textarea--invalid {
    textarea {
      border-color: $danger;
    }
  }

  &.ua-textarea--last {
    margin-bottom: 0;
  }

  &.ua-textarea--with-error {
    margin-bottom: 11px !important;
  }
}

.ua-textarea__label {
  font-size: 12px;
  line-height: 21px;
  color: $gray;
  text-transform: uppercase;
  margin-bottom: 7px;
}

.ua-textarea__control {
  font-size: 14px;
  line-height: 21px;
  width: 100%;
  border: 1px solid $light-gray;
  border-radius: 4px;
  color: $gray;
  padding: 8px 12px;
  height: 120px;

  &::placeholder {
    font-weight: normal;
    color: rbga($gray, 0.5);
  }

  &:focus {
    outline: none;
    border-color: $secondary;
  }
}
