@import '../../assets/scss/variables';
@import '../../assets/scss/mixins/ua-scrollbar';

.settings-page {
  .page-content {
    background-color: $white;
    border-radius: 4px;
    position: relative;

    &.page-content__left-blur::before,
    &.page-content__right-blur::after {
      content: '';
      position: absolute;
      width: 40px;
      height: 42px;
      top: 0;
      z-index: 1;
    }

    &.page-content__left-blur::before {
      left: 0;
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 1) 20%,
        rgba(255, 255, 255, 0) 100%
      );
    }

    &.page-content__right-blur::after {
      right: 0;
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 80%
      );
    }
  }
}

.settings-tabs {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: $white;

  .settings-tab {
    display: flex;
    align-items: center;
    padding: 10px 16px;
    font-size: 14px;
    line-height: 21px;
    color: $gray;
    border: none;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    margin-bottom: 0;

    svg {
      margin-right: 6px;
      path {
        fill: currentColor;
      }
    }

    &.active {
      background-color: $light-gray;
      color: $dark-gray;
      border: none;
      border-bottom: 1px solid $light-gray;
    }
  }

  ~ .tab-content {
    .tab-pane {
      padding: 28px 30px;

      &.tab-pane--manage-users {
        padding-top: 17px;
        padding-bottom: 23px;
      }
    }
  }
}

.settings-tab__title {
  font-size: 28px;
  line-height: 30px;
  color: $dark-gray;
  font-weight: 300;
  margin-bottom: 32px;
}

@media (max-width: 991.98px) {
  .settings-tabs {
    @include ua-scrollbar;

    flex-wrap: nowrap;
    overflow-x: auto;
  }

  .settings-tab {
    justify-content: center;
    white-space: nowrap;
  }
}

@media (max-width: 700px) {
  .settings-tabs {
    border-bottom: none;
  }
}

@media (max-width: 767.98px) {
  .settings-tabs {
    ~ .tab-content {
      .tab-pane {
        padding: 20px 24px 20px 25px;
      }
    }
  }

  .settings-tab__title {
    padding-left: 10px;
    padding-right: 10px;
  }
}
