@import './assets/scss/index';

@import '~bootstrap/scss/root';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/alert';
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/custom-forms';
@import '~bootstrap/scss/spinners';

@import '~react-toastify/scss/main.scss';

body {
  font-family: $font-family-sans-serif;
  overflow-x: hidden;
}

#root {
  height: 100%;
  height: -webkit-fill-available;
}

.btn-primary {
  background-color: #447df7;
  border-color: #447df7;
}

.hide-arrow.tooltip .arrow {
  display: none !important;
  border-top-color: transparent !important;
}
