@mixin ua-scrollbar() {
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-track {
    background: $bg-blue;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: $primary;
    transition: background 0.25s ease-in;

    &:hover {
      background: darken($primary, 5%);
    }
  }
}
