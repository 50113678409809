@font-face {
    font-family: 'Roboto';
    src: url('./fonts/Roboto-MediumItalic.ttf');
    src: local('Roboto Medium Italic'), local('Roboto-MediumItalic'),
        url('./fonts/Roboto-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('./fonts/Roboto-Italic.ttf');
    src: local('Roboto Italic'), local('Roboto-Italic'),
        url('./fonts/Roboto-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('./fonts/Roboto-Bold.ttf');
    src: local('Roboto Bold'), local('Roboto-Bold'),
        url('./fonts/Roboto-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('./fonts/Roboto-Regular.ttf');
    src: local('Roboto'), local('Roboto-Regular'),
        url('./fonts/Roboto-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('./fonts/Roboto-Medium.ttf');
    src: local('Roboto Medium'), local('Roboto-Medium'),
        url('./fonts/Roboto-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('./fonts/Roboto-BoldItalic.ttf');
    src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'),
        url('./fonts/Roboto-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('./fonts/Roboto-ThinItalic.ttf');
    src: local('Roboto Thin Italic'), local('Roboto-ThinItalic'),
        url('./fonts/Roboto-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('./fonts/Roboto-Black.ttf');
    src: local('Roboto Black'), local('Roboto-Black'),
        url('./fonts/Roboto-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('./fonts/Roboto-Light.ttf');
    src: local('Roboto Light'), local('Roboto-Light'),
        url('./fonts/Roboto-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('./fonts/Roboto-LightItalic.ttf');
    src: local('Roboto Light Italic'), local('Roboto-LightItalic'),
        url('./fonts/Roboto-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('./fonts/Roboto-BlackItalic.ttf');
    src: local('Roboto Black Italic'), local('Roboto-BlackItalic'),
        url('./fonts/Roboto-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('./fonts/Roboto-Thin.ttf');
    src: local('Roboto Thin'), local('Roboto-Thin'),
        url('./fonts/Roboto-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}
