@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins/ua-scrollbar';

.projects-table-container,
.projects-kanban-container {
  background-color: $white;
  border-radius: 4px;
  border: 1px solid $light-gray;
  padding: 15px;
  max-width: 100%;
  flex: 1 0 100%;
  transition: all 0.33s ease;
}

.col-label {
  .th-ascend {
    transform: rotate(180deg);
    margin-right: 0;
  }

  .th-descend {
    margin-left: 0;
  }

  .th-close {
    width: 21px;
    height: 20px;
    margin-right: 2px;

    path {
      fill: $dark-gray;
    }
  }

  .th-ascend,
  .th-descend,
  .th-close {
    &.is-active {
      color: $blue;
    }
  }
}

.projects-table {
  @include ua-scrollbar;

  width: 100%;
  display: block;
  overflow: auto;

  @media (max-width: 1024px) {
    overflow: hidden;
  }

  .resizer {
    right: 1px;
    position: absolute;
    top: 0;
    bottom: 7px;
    padding: 0 6px 0 10px;
    touch-action: none;
    z-index: 1;
    user-select: none;

    .resizer__item {
      background: $light-gray;
      width: 1px;
      height: 100%;

      &.resizer__item--resizing {
        background: $gray;
        user-select: none;
      }
    }
  }

  .td {
    border-top: 1px solid $light-gray;
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 21px;
    padding-top: 10px;
    padding-bottom: 9px;
  }

  .th,
  .td {
    padding-bottom: 7px;
    width: 100%;

    &.checkbox {
      display: flex;
      align-items: center;
      width: auto;
      min-width: 32px;
      color: $gray;
    }
  }

  .tbody {
    .archived-project-title {
      margin-top: 40px;
      margin-bottom: 10px;
      color: $dark-gray;
    }

    .tr {
      position: relative;

      & .archived-project-bg {
        background: rgba(255, 255, 255, 0.75);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
      }

      &.project-widgets-row {
        border-top: none;
        padding: 0;
        display: flex;
        flex: 1 0 auto;

        .project-widgets-cell {
          padding: 0;
          height: auto;
          transition: padding 0.33s ease-in;
          width: 100%;
        }

        .project-widget__item-wrapper {
          min-width: 260px;
        }

        .project-widget {
          padding: 0 25px;
        }

        .project-widgets-container {
          display: flex;
          height: 100%;
          max-height: 0;
          transition: max-height 0.33s ease-in;
          overflow: hidden;
          padding: 0 10px;

          & .ua-editable-field__content {
            max-height: 64px;
            overflow-y: visible;
          }

          @media (min-width: 1025px) {
            & .project-widget {
              flex: 1;
              max-width: 50%;
            }
          }

          @media (max-width: 1024px) {
            .project-widget {
              padding: 0;
              width: 100%;
              max-width: 100%;
              flex-basis: 100%;
              min-width: 0;
              margin-bottom: 30px;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }

        &.open {
          .project-widgets-cell {
            padding: 20px 0 11px;
            background: rgba(242, 244, 249, 0.5);
          }

          .project-widgets-container {
            max-height: 110px;
          }

          .project-widget {
            transition: all 0.33s ease-in;
            overflow: hidden;
            &.hidden {
              width: 0 !important;
              max-width: 0 !important;
              flex: 0 0 0 !important;
              min-width: 0 !important;
              padding: 0 !important;
              opacity: 0;

              @media (max-width: 1024px) {
                height: 0;
                margin: 0;
              }
            }
          }
        }

        @media (max-width: 1024px) {
          .project-widgets-container {
            display: flex;
            flex-wrap: wrap;
            min-width: 100%;
          }

          &.open {
            .project-widgets-container {
              max-height: 1000px;
            }

            .td {
              padding-bottom: 30px;
            }
          }

          .project-widget__item-wrapper {
            min-width: auto;
          }
        }
      }
    }

    .col-label {
      display: none;
    }

    .col-data {
      display: block;
      width: 100%;
    }

    .td {
      &.empty {
        padding: 30px 0 0;
        font-size: 14px;
        line-height: 24px;
        color: $gray;
        text-align: center;
      }
    }
  }

  .thead {
    .th {
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      color: $gray;

      .col-label {
        display: flex;
        align-items: center;
        cursor: pointer;
        user-select: none;
      }
    }
  }

  .project-widgets {
    button {
      background: none;
      padding: 1px;
      border: none;
      line-height: 1;
      border-radius: 2px;

      &.active {
        background-color: $light-gray;
        color: white;
      }
    }

    svg {
      width: 14px;
      height: 14px;
    }

    span {
      color: $gray;
      font-size: 14px;
      margin-left: 3px;
      margin-right: 6px;
    }
  }

  .edit-button {
    display: none;
    padding: 0 8px;
  }

  .project-title {
    cursor: pointer;
    overflow-wrap: break-word;

    &:hover > .edit-button {
      display: inline-block;
    }
  }

  .project-title-edit {
    margin-bottom: 8px;

    & .ua-input__control {
      font-size: 10px;
      padding: 3px 13px;
      line-height: 1.5;
    }
    & .ua-input__control.ua-input__control--with-icon {
      padding-right: 30px;
    }
    & .ua-input__icon {
      height: 100%;
      right: 0;
    }
  }

  .loading-cell {
    padding: 32px 0 0;
    display: flex;
    justify-content: center;

    .spinner-border {
      border-color: $gray;
      border-right-color: transparent;
    }
  }
}


@media (max-width: 1024px) {
  .projects-table-container,
  .projects-kanban-container {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 22px;
  }

  .projects-table-container {
    .table-container {
      padding-bottom: 0;
    }
  }

  .project-widgets {
    width: 100%;
  }

  .projects-table {
    .td {
      padding-top: 13px;
      padding-bottom: 13px;
    }

    .dropdown-toggle {
      height: auto;
    }

    .th,
    .td {
      &.members,
      &.client,
      &.tags,
      &.priority,
      &.due-date {
        display: none !important;
      }

      &.name {
        min-width: unset !important;
        width: unset !important;
        flex: unset !important;
      }

      &.status {
        justify-content: flex-end !important;
        flex: 1 !important;

        .col-data {
          .dropdown-toggle {
            margin-left: auto;
          }
        }
      }

      &.checkbox {
        .ua-checkbox {
          margin-left: 12px;
        }
      }
    }

    .td.status {
      vertical-align: top;

      .ua-dropdown {
        height: auto;
      }
    }

    .th {
      &.status {
        .col-label {
          display: inline-flex;
        }
      }
    }

    .tbody {
      .tr.project-widgets-row {
        padding-bottom: 0;

        .project-widget {
          padding-bottom: 15px;
        }
      }
    }
  }

  .resizer {
    display: none;
  }
}

.highlight {
  background: yellow;
}
