.card-inputs {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  > div {
    width: 100%;
  }

  &__wrap {
    display: flex;
    justify-content: space-between;

    > div {
      width: calc(50% - 5px);
    }
  }
}

.card-inputs--edit-billing {
  display: flex;
  justify-content: space-between;

  .card-inputs__wrap {
    display: flex;
    justify-content: space-between;

    > div {
      width: calc(50% - 10px);
    }
  }
}

@media (min-width: 720px) {
  .card-inputs {
    flex-wrap: nowrap;

    > div {
      width: calc(50% - 15px);
    }

    &__wrap {
      display: flex;
      justify-content: space-between;

      > div {
        width: calc(50% - 5px);
      }
    }
  }

  .card-inputs--edit-billing {
    > div {
      width: calc(50% - 10px);
    }

    .card-inputs__wrap > div {
      width: calc(57% - 10px);

      &:last-child {
        width: calc(43% - 10px);
      }
    }
  }
}
