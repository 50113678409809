@import 'src/assets/scss/variables';

.request-reset-form {
  .ua-input {
    margin-bottom: 28px;
  }
  .reset-submit {
    padding-left: 30px;
    padding-right: 30px;
  }
  .reset-go-back {
    flex: 1;

    & > a {
      width: 100%;
      background: transparent;
      border: none;

      &:hover {
        background: $light-gray;
      }
    }
  }
}

@media (max-width: 575.98px) {
  .request-reset-form {
    .reset-submit {
      margin-bottom: 12px;
    }

    .reset-go-back > a,
    .reset-submit {
      min-width: 180px;
    }

    .form__controls-row {
      flex-direction: column-reverse;
      align-items: center;
    }
  }
}
