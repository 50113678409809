@import '../../../../assets/scss/variables';

.project-due-date__button {
  display: flex;
  align-items: center;
  padding: 0 5px;
  background-color: transparent;
  border: none;
  transition: background-color 0.15s ease;
  border-radius: 4px;

  svg {
    margin-right: 5px;
  }

  &:hover {
    background-color: opacify($bg-blue, 0.15);
  }
}

.project-due-date__button-label {
  font-size: 14px;
  line-height: 21px;
}
