@import 'assets/scss/variables';

.account-settings {
  display: flex;
  margin-left: -10px;
  margin-right: -10px;

  @media (max-width: 767.98px) {
    flex-wrap: wrap;
  }
}

.account-settings__col {
  padding-left: 10px;
  padding-right: 10px;

  @media (max-width: 767.98px) {
    width: 100%;
    padding-bottom: 20px;

    &:last-child {
      padding-bottom: 0;
    }
  }
}

.upload-logo {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid $light-gray;
  padding: 30px 29px;
  position: relative;

  @media (min-width: 768px) {
    width: 230px;
  }
}

.upload-logo__loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: $primary;

  background-color: rgba($color: #fff, $alpha: 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload-logo__title,
.account-info__title {
  font-size: 28px;
  line-height: 30px;
  font-weight: 300;
  text-align: center;
  color: $dark-gray;
  margin-bottom: 40px;
}

.upload-logo__dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 20px;
  border: 1px dashed $gray;
  border-radius: 4px;
  background: $white;
  outline: none;
  margin-bottom: 30px;
  cursor: pointer;

  &.upload-logo__dropzone--active {
    border-color: $primary;
  }

  &.upload-logo__dropzone--accept {
    border-color: $success;
  }

  &.upload-logo__dropzone--reject {
    border-color: $danger;
  }
}

.upload-logo__dropzone-label {
  font-size: 14px;
  line-height: 21px;
  color: $gray;
  margin-bottom: 0;
}

.upload-logo__dropzone-icon {
  width: 30px;
  height: 30px;
}

.upload-logo__preview-container {
  position: relative;
  align-self: center;
  margin-top: 10px;
  margin-bottom: 37px;
}

.upload-logo__preview {
  width: 96px;
  height: 96px;
  border-radius: 50%;
  border: 4px solid rgba(68, 125, 247, 0.3);
  object-fit: cover;
  user-select: none;
}

.upload-logo__preview-delete {
  position: absolute;
  top: 0;
  right: 0;

  background: white;
  border-radius: 50%;
  border: 0.5px solid #e7e7e9;
  color: $gray;
  padding: 0;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: $blue;
    border-color: $blue;
    color: white;
  }
}

.account-info {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid $light-gray;
  padding: 30px 30px 20px;
}

.account-info__title {
  margin-bottom: 12px;
  text-align: left;

  @media (max-width: 767.98px) {
    text-align: center;
  }
}

.account-info__actions-row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
  justify-content: center;
}

.account-info__actions-col {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
}

.account-info__action {
  background: none;
  border: 2px solid $gray;
  border-radius: 4px;
  padding: 10px 16px;
  text-align: center;
  font-size: 14px;
  line-height: 16px;
  min-width: 150px;
  span {
    display: block;
    min-width: 114px;
    text-align: center;
  }

  &.account-info__action--warning {
    border-color: $warning;
    color: $warning;
  }

  &.account-info__action--danger {
    border-color: $danger;
    color: $danger;
  }

  &.account-info__action--primary {
    border-color: $primary;
    color: $primary;
  }

  &:disabled {
    border-color: $gray;
    color: $gray;
  }
}

.account-info__form {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.account-info__form-input {
  margin-bottom: 30px;
}

.account-info__form-submit {
  margin-left: auto;
  margin-right: auto;
}
