@import '../../assets/scss/variables';

.kanban-column-title {
  font-size: 12px;
  line-height: 13px;
  font-weight: 700;
  color: $gray;
  text-transform: uppercase;

  &.info-needed {
    color: $warning;
  }

  &.on-hold {
    color: $primary;
  }

  &.on-track {
    color: $dark-blue;
  }

  &.completed {
    color: $success;
  }

  &.cancelled {
    color: $danger;
  }
}

.kanban-droppable-container {
  width: 100%;
  min-height: 1px;
}

.kanban-column-container {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  column-gap: 20px;
}

.kanban-empty {
  padding: 0 10px;
  width: 100%;
  text-align: center;
  color: $gray;
}
