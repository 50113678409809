@import '../../../../assets/scss/variables';

.billing-account-info__list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(auto, 280px));
  grid-gap: 1rem;
  justify-content: start;
  align-items: stretch;
  margin-bottom: 1rem;
}

.billing-account-info {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 29px;
}

.billing__card__info-icon {
  margin-left: 1rem;
}

.billing-account-info__seat-button {
  background-color: transparent;
  border: none;

  & svg {
    width: 16px;
    height: 16px;
  }

  & path {
    fill: $primary;
  }
}

.billing-account-info__title {
  margin-bottom: 20px;
}

.billing-price {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
  font-weight: 300;
}
.billing-price__number {
  font-size: 36px;
  color: $blue;
  margin-bottom: 0;
  line-height: 39px;

  &::first-letter {
    color: $dark-gray;
  }
}
.billing-price__period {
  font-size: 22px;
  line-height: 30px;
  margin-left: 10px;
}

.feature-list {
  margin-bottom: 30px;
}
.feature-list__item {
  font-size: 16px;
  display: flex;
  align-items: center;
}
.feature-list__icon {
  margin-right: 12px;

  path {
    fill: $blue;
  }
}

.billing-account-info__start-now {
  width: 102.1%;
  height: 40px;
  margin-top: auto;
  font-weight: 400;
  font-size: 14px;

  &--grey,
  &--grey:hover {
    background-color: $gray !important;
    border-color: $gray !important;
  }
}

.billing-account-info__type-switcher {
  display: flex;
  margin-bottom: 16px;

  & .custom-switch {
    margin-left: 8px;
  }
}
