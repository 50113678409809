@import '../project-widget.styles.scss';

.project-update__record {
  display: flex;
  max-height: 30px;
  align-items: center;

  &:last-child {
    margin-bottom: 0;
  }
}

.project-update__record-user {
  margin-right: 10px;
}

.project-update__record-description {
  font-size: 10px;
  line-height: 14px;
  color: $dark-gray;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.project-update__action {
  background: none;
  border: none;
  color: $info;
  font-size: 10px;
  line-height: 14px;
}

.project-update__list-control {
  width: 100%;
  text-align: center;
}

.project-update__list-expander {
  font-size: 14px;
  line-height: 16px;
  background-color: transparent;
  border: none;
  padding: 0;
  text-decoration: underline;
  color: $primary;

  &:focus {
    outline: none;
  }

  &:hover {
    color: darken($primary, 5%);
  }
}

.project-update__add-container {
  padding-top: 8px;
  display: flex;
  justify-content: center;
}

.project-update__add-update {
  background: none;
  border: none;
  color: $info;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;

  svg {
    width: 20px;
    height: 20px;
    margin-right: 8px;

    path {
      fill: currentColor;
    }
  }
}
