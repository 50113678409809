@import '../project-widget.styles';

.project-info {
  .project-widget__content {
    font-size: 14px;
    line-height: 1.5;
  }

  .project-widget__header-action {
    button {
      display: flex;
      align-items: center;
      background: none;
      border: none;
      font-size: 14px;
      line-height: 21px;
      padding: 0;

      svg {
        margin-right: 5px;

        path {
          fill: currentColor;
        }
      }
    }
  }
}

.project-info__edit-button {
  color: $gray;
}

.project-info__save-button {
  color: $info;
}

.project-info__info-text {
  & .ua-editable-field__content {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
