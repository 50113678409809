@import 'src/assets/scss/variables';

.dashboard-page {
  .dashboard-page-container {
    display: flex;
    gap: 40px;
    flex-wrap: wrap;

    &__item {
      flex: 1 0;
      min-width: 700px;
      max-width: 50%;

      @media (max-width: 1024px) {
        min-width: 100%;
        max-width: unset;
      }
    }
  }

  .trial-container {
    padding: 8px 12px;
    border-radius: 8px;
    border: 1px solid $yellow;
    background-color: white;
    color: $yellow;
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    font-weight: 300;
  }

  .trial-message {
    margin: 0 0 0 8px;
  }

  .create-board-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 15px 18px 16px;
    border-radius: 4px;
    border: 2px dashed $primary;
    color: $primary;
    font-size: 16px;
    line-height: 17px;
    background-color: transparent;

    svg {
      margin-right: 4px;
    }
  }
}

div.dashboard-page-loader {
  height: calc(100vh - 240px);
}
