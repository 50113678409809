.form {
  display: flex;
  flex-direction: column;
  padding: 40px;
  background: #fff;
  box-shadow: 0 30px 60px rgba(40, 40, 40, 0.2);
  border-radius: 4px;
}

.form-card {
  width: 100%;
  padding: 100px 80px;
  font-size: 22px;
  font-weight: 300;
  line-height: 30px;
  text-align: center;
  color: $dark-gray;
  max-width: 380px;
  margin-left: auto;
  margin-right: auto;
}

.form__controls-row {
  display: flex;
  margin-left: -10px;
  margin-right: -10px;

  .ua-input {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }

  .form__controls-group {
    padding-left: 10px;
    padding-right: 10px;

    > div {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.form__action-row {
  justify-content: center;
}

.form__caption {
  font-size: 22px;
  line-height: 30px;
  font-weight: 300;
  text-align: center;
  color: $dark-gray;
  margin-bottom: 32px;
}

.form--with-hint {
  .form__caption {
    margin-bottom: 20px;
  }
}

.form__hint {
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: $gray;
  padding: 0 40px;
  margin-bottom: 32px;
}

.form__controls {
  display: flex;
  flex-direction: column;
}

.form-auth {
  width: 100%;
  max-width: 380px;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  .form__submit {
    width: 120px;
    margin-left: auto;
    margin-right: auto;
  }

  .form__link {
    margin-top: 22px;
    padding: 0;
  }

  .form__help-text {
    margin-bottom: 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;

    & .form__link {
      margin-top: 0;
    }
  }

  .requirements {
    margin-bottom: 9px;
    margin-top: 9px;
    height: 42px;
    transition: height 0.33s, margin 0.33s ease;
    overflow: hidden;
  }
}

.form-card--verification {
  padding: 100px 30px;
  min-height: 322px;
  flex-direction: column;
  justify-content: center;

  .loader-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .spinner-border {
    border-color: $gray;
    border-right-color: transparent;
  }

  .column {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px 0;
    gap: 12px;

    & > * {
      min-width: 200px;
    }
  }

  .links {
    margin-top: 6px;

    > * {
      padding: 0 4px;
      background: none;
      border: none;
      color: $primary;
      font-weight: 300;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

@media (max-width: 575.98px) {
  .form-auth {
    padding: 15px;
    margin-bottom: 80px;

    .requirements {
      margin-bottom: 20px;
    }
  }

  .form__controls-row {
    flex-direction: column;
  }
}

@media (max-width: 410px) {
  .form__hint {
    padding: 0;
    margin-bottom: 22px;
  }

  .form-card {
    padding: 60px 30px;
  }
}
