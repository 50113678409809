@import 'src/assets/scss/variables';

.add-client-form {
  height: 280px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > .add-client-form-controls {
    display: flex;
    flex-direction: column;

    & > button {
      margin-top: 15px;
    }
  }
}
