@import 'src/assets/scss/variables';

.client-list-container {
  height: 280px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > .client-list {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 0 0 8px;
    padding: 0;
    overflow: scroll;
  }
}
