@import 'assets/scss/variables.scss';

.ua-input {
  display: block;
  margin-bottom: 12px;
  position: relative;

  &.ua-input--valid {
    .ua-input__control {
      border-color: $success;
    }
  }

  &.ua-input--invalid {
    .ua-input__control {
      border-color: $danger;
    }
  }

  &.ua-input--last {
    margin-bottom: 0;
  }

  &.ua-input--with-error {
    margin-bottom: 11px !important;
  }
}

.ua-input__label {
  display: block;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 1.75;
  color: $secondary;
  margin-bottom: 7px;

  &.ua-input__label--flex {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }
}

.ua-input__container {
  position: relative;
}

.ua-input__control {
  width: 100%;
  height: 40px;
  font-size: 14px;
  line-height: 21px;
  color: $dark-gray;
  border: 1px solid $light-gray;
  border-radius: 4px;
  padding: 8px 13px;
  -webkit-appearance: none;
  letter-spacing: -0.1px;
  font-family: $font-family-sans-serif;

  &::placeholder {
    font-weight: normal;
    color: $secondary;
  }

  &.ua-input__control--with-icon {
    padding-right: 48px;
  }

  &.ua-input__control--with-prepend-icon {
    padding-left: 40px;
  }

  &.ua-input__control--with-close-icon {
    padding-right: 40px;
  }

  &:focus {
    outline: none;
    border-color: $secondary;
  }
}

.ua-input__icon {
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  right: 8px;

  &.ua-input__icon--clickable {
    cursor: pointer;
  }

  &.ua-input__icon--prepend {
    right: 0;
    left: 13px;
  }
}

.ua-input__error {
  font-size: 12px;
  line-height: 17px;
  color: $danger;
  padding-left: 14px;
  padding-top: 3px;
}
