@import '../../assets/scss/variables';
@import '../../assets/scss/components/requirements';

.registration-content {
  display: flex;
  align-items: stretch;
  max-width: 1300px;

  @media (max-width: 425px) {
    flex-direction: column;
  }
}

.registration-content__left {
  width: 53%;
  padding: 40px 25px;
  background: #d5ddf1 url('../../assets/images/auth_form_bg.svg') no-repeat
    center;
  background-size: cover;

  @media (max-width: 425px) {
    width: 100%;
    padding: 0;
    padding-top: 40px;
    padding-bottom: 60px;
    color: $light-gray;
    background: none;
  }
}

.registration-content__right {
  width: 47%;
  height: 100%;

  & .form {
    max-width: 100%;
    padding-left: 85px;
    padding-right: 85px;
    border-radius: 0;
    height: 100%;

    @media (max-width: 1024px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  @media (max-width: 425px) {
    width: 100%;
  }
}

.welcome-section {
  text-align: center;
}

.welcome-section__title {
  font-weight: 300;
  font-size: 28px;
  line-height: 30px;
  color: #333333;
  text-align: center;

  @media (max-width: 425px) {
    color: $light-gray;
  }
}

.welcome-section__desc-wrapper {
  position: relative;
  margin-top: 44px;
  text-align: left;

  @media (max-width: 425px) {
    margin-top: 24px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
  }
}

.welcome-section__desc {
  padding: 0 70px;
  padding-right: 65px;
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;

  @media (max-width: 1024px) {
    padding: 0 12px;
    padding-right: 12px;
  }

  &--with-img {
    padding-right: 250px;

    @media (max-width: 1024px) {
      padding-right: 12px;
    }
  }
}

.welcome-section__advantages {
  position: absolute;
  top: 100%;
  right: 0;
  min-width: 236px;
  margin-top: -26px;
  padding: 14px 23px;
  border-radius: 4px;
  background: linear-gradient(
    109.8deg,
    rgba(255, 255, 255, 0.98) 0%,
    rgba(243, 246, 251, 0.98) 100%
  );
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.2);

  @media (max-width: 1024px) {
    margin-top: 0;
  }

  @media (max-width: 425px) {
    right: 15px;
    margin-top: 40px;
    padding-top: 5px;
    padding-bottom: 5px;
    min-width: 210px;
  }
}

.welcome-section__advantages-list {
  margin: 0;
  padding: 0;
  padding-left: 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: $dark-blue;
  text-align: left;

  @media (max-width: 425px) {
    padding-left: 18px;
  }
}

.welcome-section__decoration-img {
  margin-top: 45px;

  img {
    display: block;
    width: 100%;
  }

  @media (max-width: 425px) {
    margin-top: 80px;
  }
}

.registration-content__success {
  display: flex;
  align-items: center;
  height: 100%;
}

.registration {
  .form-card {
    padding: 60px 60px;
    font-size: 18px;
    line-height: 24px;

    & > a {
      margin-top: 12px;
    }
  }
}

@media (max-width: 410px) {
  .registration {
    .form-card {
      padding: 60px 15px;
    }
  }
}

.registration-content__mobile-navigation {
  display: none;
  margin-top: 30px;

  @media (max-width: 425px) {
    display: block;
  }
}

.mobile-navigation__button {
  width: 58px;
  height: 58px;
  padding: 0;
  margin: 0;
  margin-top: 22px;
  color: #ffffff;
  background: rgba(255, 255, 255, 0.3);
  border: none;
  border-radius: 50%;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
}

.mobile-navigation__button-icon {
  width: 20px;
  height: 20px;
}
