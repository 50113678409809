@import 'src/assets/scss/variables';

.client-list__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  padding: 4px 8px;
  margin-right: 6px;
  border-radius: 4px;
  margin-bottom: 4px;

  &.selected {
    background: $light-gray;
  }

  &:hover {
    background: $gray;
    color: white;
  }
}

.client-list-item__item-name {
  user-select: none;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px;
}

.client-list__item-control {
  background: none;
  border: none;
  width: 30px;
  height: 30px;
  border-radius: 4px;

  &:hover {
    background: $primary;
    color: $white;
  }
}

.delete-client {
  color: $red;
}
