@import 'src/assets/scss/variables';
@import 'src/assets/scss/mixins/ua-scrollbar';

.ua-bottom-menu {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: $white;
  box-shadow: 0 -4px 6px rgba(8, 22, 52, 0.08);
  width: 100%;
  z-index: 100;

  @media (min-width: 1025px) {
    display: none;
  }

  &.hide {
    display: none;
  }
}

.ua-bottom-menu-content {
  @include ua-scrollbar;

  padding: 16px 16px 20px;
  overflow-x: auto;
}
