@import 'assets/scss/variables';

.payment-information {
  padding-top: 28px;
  max-width: none;
  padding-bottom: 14px;
}

.payment-information__card-inputs {
  margin-top: 32px;
}
