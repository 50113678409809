@import '../../../assets/scss/variables';

.priority-dropdown .btn {
  padding: 0;
  min-width: 80%;

  &:disabled {
    opacity: 1;
  }
}
.priority {
  display: inline-flex;
  align-items: center;
  color: $gray;
  text-transform: capitalize;
  font-size: 14px;
  line-height: 21px;

  &.low {
    color: $success;

    .priority-dot {
      background-color: $success;
    }
  }

  &.medium {
    color: $warning;

    .priority-dot {
      background-color: $warning;
    }
  }

  &.high {
    color: $danger;

    .priority-dot {
      background-color: $danger;
    }
  }

  .priority-dot {
    width: 8px;
    height: 8px;
    margin-right: 8px;
    border-radius: 50%;
    background-color: $gray;
  }

  &.priority--no-label {
    .priority-dot {
      margin-right: 0;
    }
  }

  &.priority--with-background {
    padding: 0 8px;
    border-radius: 4px;

    &.low {
      background-color: rgba($success, 0.1);
    }

    &.medium {
      background-color: rgba($warning, 0.1);
    }

    &.high {
      background-color: rgba($danger, 0.1);
    }
  }
}
