.requirements {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  overflow: hidden;

  &.requirements--hidden {
    margin: 0;
    height: 0;
  }
}

.requirement-item {
  flex: 1 1 50%;
  max-width: 50%;
  display: flex;
  align-items: center;
  color: $danger;
  padding-left: 10px;

  &.requirement-item--passed {
    color: $success;
  }
}

.requirement-item__icon {
  display: flex;
  align-items: center;
  padding-left: 4px;
  width: 20px;
  height: 20px;
  margin-right: 6px;
}

.requirement-item__text {
  font-size: 10px;
  line-height: 21px;
  margin-bottom: 0;
}

@media (max-width: 575.98px) {
  .requirements {
    margin-left: -5px;
    margin-right: -5px;
  }

  .requirement-item {
    padding-left: 5px;
  }

  .requirement-item__icon {
    padding-left: 0;
    margin-right: 2px;
    width: 16px;
  }
}
