@import '../../../assets/scss/variables';
@import 'react-datepicker/src/stylesheets/datepicker.scss';

.ua-datepicker {
  font-family: $font-family-sans-serif;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border: none;
  height: 236px;

  .react-datepicker__header {
    border: none;
    background-color: $white;
  }

  .react-datepicker__month-container {
    float: none;
  }

  .react-datepicker__day-name {
    font-size: 12px;
    color: $dark-gray;
    font-weight: bold;
  }

  .react-datepicker__day--outside-month {
    opacity: 0.25;
  }

  .react-datepicker__day-names {
    margin-top: 20px;
  }

  .react-datepicker__day-name {
    width: 26px;
    line-height: 1;
    margin: 0 3.75px;
  }

  .react-datepicker__day {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    margin: 1px 3.75px;

    &:hover,
    .react-datepicker__day--keyboard-selected {
      background-color: $primary;
      color: $white;
    }

    &.react-datepicker__day--disabled {
      pointer-events: none;
    }
  }

  .react-datepicker__day--today {
    background-color: $bg-blue;
    color: $gray;
    font-weight: normal;
  }

  .react-datepicker__day--selected {
    background-color: $primary;
    color: $white;
  }

  .react-datepicker__month {
    margin: 0 0.4rem 0.4rem;
  }
}

.ua-datepicker__header-button {
  border: none;
  background-color: transparent;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  padding: 0;
  color: $gray;

  svg {
    path {
      fill: currentColor;
    }
  }

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: $primary;
    color: $white;
  }

  &.ua-datepicker__prev-button {
    svg {
      margin-left: -1px;
      transform: rotate(180deg);
    }
  }

  &.ua-datepicker__next-button {
    svg {
      margin-right: -1px;
    }
  }

  &[disabled] {
    background-color: $light-gray;
    color: $gray;
    opacity: 0.5;
  }
}

.ua-datepicker__header {
  display: flex;
  justify-content: space-between;
}

.ua-datepicker__header-col {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 18px;
}

.ua-datepicker__header-label {
  font-size: 14px;
  line-height: 21px;
  color: $gray;
  padding: 0 10px;
  width: 53px;
  text-align: center;

  &.ua-datepicker__header-label--month {
    padding: 0 14px;
  }
}

.ua-datepicker-modal {
  &.ua-modal {
    max-width: 280px;
    margin-left: auto;
    margin-right: auto;
  }

  .modal-content {
    height: 365px;
  }

  .modal-header {
    padding: 0;
  }

  .modal-body {
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
  }

  .ua-modal__title {
    font-size: 22px;
    margin-bottom: 5px;
  }
}

.ua-datepicker__clear-date {
  background-color: $primary;
  border-radius: 4px;
  color: $white;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  padding: 8px 47px;
  border: none;
  transition: background-color 0.15s ease;
  margin: 13px auto 0;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: darken($primary, 5%);
  }
}
