@import '../mixins/ua-scrollbar.scss';

.table-container {
  @include ua-scrollbar;

  display: block;
  width: 100%;
  padding: 0 15px 20px;
  overflow-x: auto;
}
