@import 'src/assets/scss/variables';

.tag {
  display: inline;
  padding: 0 8px;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  background-color: $gray;
  border-radius: 4px;
  margin: 1.5px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
  user-select: none;
  position: relative;
  overflow-wrap: break-word;
  max-width: 100%;

  &.tag-delete-btn {
    padding-right: 21px;
  }

  .delete-btn {
    cursor: pointer;
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);

    svg {
      width: 8px;
      height: 8px;

      path {
        fill: currentColor;
      }
    }
  }

  &.primary {
    background-color: $primary;
  }

  &.success {
    background-color: $success;
  }

  &.warning {
    background-color: $warning;
  }

  &.danger {
    background-color: $danger;
  }

  &.info {
    background-color: $info;
  }

  &.dark {
    background-color: $bg-blue;
    color: $dark-blue;
  }

  &.tag--outline {
    background-color: transparent;

    &.primary {
      border: 1px solid $primary;
      color: $primary;
    }

    &.success {
      border: 1px solid $success;
      color: $success;
    }

    &.warning {
      border: 1px solid $warning;
      color: $warning;
    }

    &.danger {
      border: 1px solid $danger;
      color: $danger;
    }

    &.info {
      border: 1px solid $info;
      color: $info;
    }
  }

  &.tag--dark {
    color: $black;
  }

  &.tag--light {
    color: $white;
  }
}
