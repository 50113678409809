.toolbar {
  display: flex;

  &.ml-10 {
    margin-left: 10px;
  }

  &.ml-30 {
    margin-left: 30px;
  }

  &.ml-14 {
    margin-left: 14px;
  }

  .toolbar-btn {
    width: 40px;
    height: 40px;
    background-color: transparent;
    border: 1px solid $gray;
    color: $gray;
    transition: all 0.15s ease;
    margin-left: -1px;
    display: flex;
    justify-content: center;
    align-items: center;

    & svg {
      width: 16px;
      height: 16px;

      path {
        fill: currentColor;
      }

      &.stroke {
        path {
          stroke: currentColor;
        }
      }
    }

    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      margin-left: 0;
    }

    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    &:hover:enabled {
      background-color: $light-gray;
    }

    &.active {
      background-color: $gray;
      color: white;
    }

    &.toolbar-btn--dropdown {
      &.ua-dropdown {
        .dropdown-item {
          font-size: 10px;
          line-height: 12px;
          padding: 4px 12px;

          svg {
            width: 10px;
            height: 10px;
          }
        }

        .dropdown-toggle {
          padding: 0;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $gray;
          border-radius: 0;
        }

        &.active {
          .dropdown-toggle {
            background-color: $gray;
            color: white;
          }
        }

        &:hover {
          .dropdown-toggle {
            background-color: $gray;
            opacity: 1;
          }
        }
      }
    }
  }

  .toolbar-search {
    margin-right: auto;
    margin-bottom: 0;
  }
}

.page {
  .page-content {
    .toolbar-container {
      .toolbar-search {
        margin-right: auto;
        margin-bottom: 0;
        width: 100%;
        min-width: 300px;

        .ua-input {
          margin-bottom: 0;
        }
      }
    }
  }
}

.toolbar-wrapper {
  display: flex;
}
