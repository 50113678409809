@import '../project-widget.styles.scss';

.project-tasks__list {
  padding: 0 10px;
  background-color: $white;
  border: 1px solid $light-gray;
  border-radius: 4px;
}

.project-tasks__task {
  display: flex;
  align-items: center;
  padding: 6px 0;
  border-top: 1px solid $light-gray;
  cursor: pointer;

  &:first-child {
    border-top: none;
  }

  &.project-tasks__task--action {
    padding: 12px 0 9px;
    justify-content: center;
    cursor: pointer;
  }

  &.project-tasks__task--input {
    padding-right: 0 !important;
  }

  .ua-input {
    width: 100%;
    margin-bottom: 0;
  }
}

.project-tasks__action {
  background: none;
  border: none;
  color: $info;
  font-size: 10px;
  line-height: 14px;
}

.project-tasks__task-check {
  margin-right: 15px;

  svg {
    width: 17px;
    height: 17px;
  }

  .ua-checkbox__icon {
    margin-right: 0;
  }
}

.project-tasks__task-description {
  font-size: 10px;
  line-height: 14px;
  color: $dark-gray;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-right: 6px;
}

.project-tasks__task-action {
  display: flex;
  align-items: center;
  margin-left: auto;

  button {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    padding: 0;
  }

  .project-tasks__task-edit {
    margin-right: 15px;
  }
}

.project-tasks__add-task {
  background: none;
  border: none;
  font-size: 14px;
  line-height: 16px;
  color: $info;
  display: flex;
  align-items: center;

  svg {
    width: 16px;
    height: 16px;
    margin-right: 10px;

    path {
      fill: currentColor;
    }
  }
}

.project-tasks-completed--label {
  display: flex;
  align-items: center;
  margin: 0 16px 0 0;
  height: 16px;
}

.project-tasks-completed--span {
  color: #23ccef;
  font-size: 10px;
  margin-left: 8px;
}
