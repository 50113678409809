@import 'src/assets/scss/variables';

.invite-user-button {
  width: 140px;
  height: 40px;
  border: none;
  background-color: $primary;
  transition: background-color 0.15s ease;
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 10px 17px;
  color: $white;
  font-size: 14px;
  line-height: 16px;

  &:hover {
    background-color: darken($primary, 5%);
  }

  img {
    margin-right: 10px;
  }
}

.invite-user-button__role {
  width: 100%;
  display: block;
  border: 1px solid #e7e7e9;
  border-radius: 4px;

  .btn {
    width: 100%;
    padding: 10px 13px;
  }
}

.invite-user-button__controls {
  display: flex;
  justify-content: space-between;
  padding-top: 24px;
}

@media (max-width: 767.98px) {
  .invite-user-button {
    width: 54px;
    span {
      display: none;
    }
  }
}
