@import '../../assets/scss/variables';

.project-card {
  width: 100%;

  & .ua-editable-field__input.textarea {
    height: 130px;
  }
}

.project-card__header {
  padding: 13px 12px 18px 18px;
}

.project-card__tagline {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;

  &-col {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
}

.project-card__title-container {
  display: flex;
  margin-bottom: 24px;
}

.project-card__title {
  color: $dark-gray;
  font-weight: 300;
  font-size: 22px;
  line-height: 30px;
  margin-bottom: 0;
  margin-right: 12px;
  overflow-wrap: break-word;
}

.project-card .ua-editable-field {
  width: 80%;
  flex: 1;
}

.project-card__priority {
  margin-top: 4px;
}

.project-card__header-bottom {
  display: flex;
  align-items: center;
}

.project-card__client {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 21px;
  color: $dark-gray;

  svg {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
}

.project-card__divider {
  margin: 0 20px;
  height: 1px;
  background-color: $light-gray;
}

.project-card__body {
  padding: 18px 20px;

  .project-widget {
    margin-bottom: 32px;
  }

  .project-widget__header-title {
    font-size: 16px;
    line-height: 17px;
    font-weight: normal;
    color: $gray;
  }

  .project-widget__item-wrapper {
    margin-bottom: 17px;
    max-width: 100%;
    flex-basis: 100%;
  }
}

.project-card__info {
  .project-widget__content {
    font-size: 14px;
    line-height: 21px;
    color: $dark-gray;
  }
}

.project-card__update {
  &.project-widget {
    margin-bottom: 18px;
  }

  .project-update__record {
    max-height: unset;
  }

  .project-update__record-description {
    font-size: 14px;
    line-height: 21px;
    color: $dark-gray;
    margin-top: 5px;
  }
}

.project-card__files {
  &.project-files {
    margin-bottom: 26px;
  }

  .project-widget__item-wrapper {
    margin-bottom: 10px;
  }

  .project-files__file {
    padding: 11px 16px 11px 20px;
  }

  .project-files__file-icon {
    flex: 0 0 20px;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .project-files__file-name {
    font-size: 14px;
    line-height: 16px;
    color: $dark-gray;
    margin-top: 3px;
  }
}

.project-card__tasks {
  .project-widget__content {
    padding-top: 18px;
  }

  .ua-checkbox__icon {
    width: 20px;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .project-tasks__task {
    padding: 18px 7px 20px 0;
  }

  .project-tasks__task-check {
    margin-right: 10px;
  }

  .project-tasks__task-description {
    font-size: 14px;
    line-height: 21px;
  }
}

.project-card__team {
  margin-right: auto;
}

.project-card-row {
  display: flex;
  margin-bottom: 24px;
}

.project-card-item {
  flex: 1;
}

.project-card-item__title {
  font-size: 16px;
  font-weight: normal;
  line-height: 17px;
  color: #888;
}

.project-card-item__content {
  width: fit-content;

  .tag-list {
    width: unset;
  }

  .project-due-date__button {
    padding: 0;
  }
}

.modal-backdrop ~ .modal-backdrop  {
  z-index: 1100;
}

.modal ~ .modal  {
  z-index: 1150;
}

.project-card .tag {
  font-size: 14px;
  padding: 4px 6px;
}
