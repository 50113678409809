@import 'src/assets/scss/variables';

.share-board-modal {
  max-width: 520px;
}

.share-board-title {
  font-size: 16px;
  color: $dark-gray;
  padding-bottom: 21px;
  margin: 0;
  text-align: center;
}

.share-board-form {
  padding: 21px 0;
}

.share-board-row {
  position: relative;
  display: flex;
  height: 40px;
}

.share-board-row-input-container {
  width: 100%;
}

.share-board-row-input {
  padding-right: 130px;
}

.share-board-row-btn {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 125px;
}
