.show-options-dropdown {
  .dropdown-item {
    &.show {
      .hide-icon {
        display: none;
      }
    }

    &.hide {
      .show-icon {
        display: none;
      }
    }
  }
}

.show-options-dropdown-overlay {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
