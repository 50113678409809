.ua-button {
  position: relative;

  &:disabled {
    pointer-events: none;
  }

  & .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
  }

  &.loading {
    & .label {
      opacity: 0;
    }

    & .loader {
      opacity: 1;
    }
  }
}
