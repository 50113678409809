@import 'assets/scss/variables';
@import 'assets/scss/mixins/ua-scrollbar';

.ua-select-container {
  width: 100%;
  position: relative;

  .react-autosuggest__suggestions-container {
    @include ua-scrollbar;

    display: block;
    opacity: 0;
    position: absolute;
    top: 45px;
    width: 100%;
    border: none;
    border-radius: 4px;
    z-index: 2;
    max-height: 200px;
    overflow-y: auto;
    background-color: $white;
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.08);
  }

  .react-autosuggest__suggestions-container--open {
    opacity: 1;
  }

  .react-autosuggest__input--focused {
    border: 1px solid $gray;
    outline: none;
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: 0;
  }

  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 6px 10px;
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: $bg-blue;
  }
}

.ua-select {
  .ua-select__placeholder,
  .ua-select__input {
    width: 100%;
    font-size: 14px;
    line-height: 21px;
    color: #333;
    border-radius: 4px;
    padding: 0;
    -webkit-appearance: none;
  }

  .ua-select__value-container {
    padding: 4px 8px;
  }

  .ua-select__control {
    border-color: $light-gray;
    transition: none;

    &:hover {
      border-color: $light-gray;
    }

    &.ua-select__control--is-focused {
      border-color: $gray;
      box-shadow: none;

      &:hover {
        border-color: $gray;
      }
    }
  }

  .ua-select__menu-list {
    @include ua-scrollbar;
  }

  .ua-select__option {
    display: flex;
    align-items: center;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 21px;
    cursor: pointer;
    color: $dark-gray;

    &.ua-select__option--focused,
    &.ua-select__option--is-selected,
    &:hover {
      background-color: $bg-blue;
      color: $dark-gray;
    }
  }
}
