@import 'assets/scss/variables';

.close-account-modal {
  max-height: 370px;

  &.ua-modal {
    max-width: 280px;
    margin-left: auto;
    margin-right: auto;
  }

  .modal-content {
    max-height: 370px;
  }

  .modal-header {
    padding: 0;
  }

  .modal-body {
    padding-left: 20px;
    padding-right: 20px;
  }

  .ua-modal__title {
    font-size: 16px;
    line-height: 1;
    margin-bottom: 33px;
  }

  .search {
    .ua-input {
      margin-bottom: 13px;
    }
  }
}

.close-account-list {
  max-height: 235px;
  overflow: auto;
  list-style-type: none;
  padding: 0;
  margin-top: 22px;
  margin-bottom: 12px;

  &::-webkit-scrollbar {
    width: 2px;
    height: 2px;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-track {
    background: $bg-blue;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: $primary;
    transition: background 0.25s ease-in;

    &:hover {
      background: darken($primary, 5%);
    }
  }
}

.close-account-list__error {
  font-size: 12px;
  line-height: 17px;
  color: #fb404b;
  padding-left: 14px;
  padding-top: 3px;
  margin-bottom: 15px;
}

.close-account-list__item {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.15s ease-in;
  border-radius: 4px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.08);
  }
}

.close-account-list__item-avatar {
  margin-right: 10px;

  .user-avatar__name {
    font-size: 10px;
    line-height: 17px;
  }
}

.close-account-list__item-name {
  font-size: 14px;
  line-height: 21px;
  color: $gray;
  margin-bottom: 0;
  margin-right: auto;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.close-account-list__item-check {
  &.hidden {
    opacity: 0;
  }
}

.close-account-list__item-empty {
  padding-left: 5px;
  text-align: center;
  font-size: 14px;
  line-height: 21px;
  color: $gray;
}

.close-account__submit-container {
  display: flex;
  justify-content: center;
  padding-top: 8px;
}

.close-account__submit {
  min-width: 130px;
}
