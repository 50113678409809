@import '../project-widget.styles.scss';

.project-files__file {
  display: flex;
  align-items: center;
  background-color: $white;
  border: 1px solid $light-gray;
  border-radius: 4px;
  padding: 3px 11px 4px;
  margin-bottom: 10px;
  width: 100%;
}

.project-files__file-icon {
  flex: 0 0 15px;
  cursor: pointer;

  svg {
    width: 15px;
    height: 15px;
    margin-right: 9px;

    path {
      fill: $dark-gray;
    }
  }
}

.project-files__file-name {
  font-size: 10px;
  line-height: 14px;
  color: $dark-gray;
  margin-right: 6px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.project-files__file-action {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.project-files__file-remove {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  padding: 0;
}

.project-files__list-control {
  width: 100%;
  padding-top: 3px;
  & * {
    cursor: pointer;
  }
}

.project-files__add-file {
  background: none;
  border: none;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  color: $info;
  font-size: 14px;
  line-height: 21px;
  font-weight: normal;
  margin-bottom: 0;

  input {
    display: none;
  }

  svg {
    width: 20px;
    height: 20px;
    margin-right: 8px;

    path {
      fill: currentColor;
    }
  }

  &:hover {
    color: darken($info, 5%);
  }
}
