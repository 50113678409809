@import 'assets/scss/variables';

.payment-cards__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.payment-cards__add-card {
  width: 140px;
  height: 40px;
  border: none;
  background-color: $primary;
  transition: background-color 0.15s ease;
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 10px 25px;
  color: $white;
  font-size: 14px;
  line-height: 16px;
  margin-right: -1px;

  &:hover {
    background-color: darken($primary, 5%);
  }

  img {
    margin-right: 10px;
  }
}

@media (max-width: 490px) {
  .payment-cards__row {
    display: block;
  }

  .payment-cards__add-card {
    margin-top: 10px;
  }
}

