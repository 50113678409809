@import 'src/assets/scss/variables';

.support-link-container {
  padding: 20px;
  width: 100%;
  max-width: 260px;
  background: #1a3b83;

  .help-link {
    display: flex;
    align-items: center;
    padding: 10px 5px;
    margin-bottom: 4px;
    font-size: 12px;
    font-weight: 700;
    color: $white;
    text-decoration: none;
    text-transform: uppercase;
    transition: all 0.33s ease;
    border-radius: 4px;

    &:last-child {
      margin-bottom: 0;
    }

    svg {
      margin-right: 13px;
      flex: 0 0 30px;

      path {
        fill: currentColor;
      }
    }

    &:hover,
    &:active,
    &.active {
      background-color: rgba(255, 255, 255, 0.15);
    }
  }
}

.layout {
  display: block;
  height: 100vh;
  overflow-y: auto;
  background-color: $dashboard-background;
}

.layout__content {
  flex: 1;
  padding: 80px 0 0 260px;
  transition: padding 0.33s ease-in;
}

.layout__main {
  padding: 20px 30px;
}

.layout__header {
  width: calc(100% - 260px);
  height: 80px;
  padding: 20px 6px 20px 30px;
  border-bottom: 1px solid $light-gray;
  display: flex;
  align-items: center;
  background-color: $bg-blue;
  position: fixed;
  left: 260px;
  z-index: 3;
  transition: left 0.33s ease-in, width 0.33s ease-in;

  .profile-dropdown {
    margin-left: auto;
  }

  &.layout__header--guest {
    width: 100%;
    left: 0;
    justify-content: space-between;

    & > .header-controls__container {
      display: flex;
      & > * {
        margin-right: 12px;
      }
    }

    ~ .layout__content {
      width: 100%;
      padding-left: 0;
    }
  }
}

.layout__header-controls {
  margin-left: auto;
  padding: 0 10px;

  + .profile-dropdown {
    margin-left: 0;
  }
}

.layout-dashboard {
  .layout__header {
    .search {
      margin-left: 31px;
      max-width: 300px;
      margin-right: auto;
      display: none;

      .ua-input {
        margin-bottom: 0;
        input {
          border-color: $light-gray;
        }
      }
    }

    .navigation-toggler {
      border: none;
      background: none;
      padding: 0;
      margin-right: 20px;
    }

    .page-title {
      font-size: 16px;
      line-height: 17px;
      color: $dark-blue;
      text-transform: uppercase;
      display: flex;
      align-items: center;

      .page-icon {
        margin-right: 10px;
      }

      .page-title-link {
        text-decoration: none;
        color: $dark-blue;
        display: flex;
        align-items: center;
      }
    }
  }

  .layout__main {
    .search {
      margin-bottom: 32px;
    }
  }

  .layout__main-controls {
    display: none;

    + .search {
      margin-top: 16px;
    }
  }
}

.sidebar__menu {
  padding: 30px 20px 0;
  transition: padding 0.33s ease-in;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .menu-item {
    position: relative;
    transition: margin 0.33s ease-in;

    &.menu-item--search {
      .search {
        padding: 0 10px;
      }
    }
  }

  .menu-item__truncate {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: all 0.33s ease-in;
    z-index: 1;
  }

  .menu-item__expanded {
    width: 220px;
    transition: all 0.33s ease-in;
    z-index: 0;
  }

  .sidebar-btn {
    display: flex;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: transparent;
    border: none;
    color: $white;
    padding: 5px;
    border-radius: 4px;
    transition: background-color 0.33s ease;

    svg {
      width: 30px;
      height: auto;

      path {
        fill: currentColor;
      }
    }

    &:hover,
    &.active {
      background-color: rgba(255, 255, 255, 0.15);
    }
  }

  .sidebar-link {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 13px;
    font-weight: bold;
    color: $white;
    text-decoration: none;
    text-transform: uppercase;
    background-color: transparent;
    transition: all 0.33s ease;
    border-radius: 4px;
    font-family: $font-family-sans-serif;

    svg {
      margin-right: 13px;
      flex: 0 0 30px;

      path {
        fill: currentColor;
      }
    }

    span {
      transition: all 0.33s linear;
      position: relative;
    }

    &:hover,
    &:active,
    &.active {
      background-color: rgba(255, 255, 255, 0.15);
    }
  }
}

.layout__sidebar,
.sidebar {
  display: flex;
  flex-direction: column;
  width: 0;
  flex: 0 0 260px;
  height: 100%;
  background: #1a3b83;
  background-size: 260px auto;
  overflow: hidden;
  transition: width 0.33s ease-in, flex 0.33s ease-in;
  position: fixed;
  left: 0;

  .profile-dropdown {
    display: none;
  }

  .ua-collapse {
    .ua-collapse__label {
      position: relative;
      z-index: 0;
      cursor: pointer;
    }

    .ua-collapse__toggler {
      z-index: 2;
      position: absolute;
      right: 5px;
      top: 5px;
      height: 30px;
      padding: 5px;
      border: 0;
      background: none;
      color: $white;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.33s ease;
      border-radius: 4px;
      transform: rotate(180deg);

      svg {
        margin-right: 0;
        path {
          fill: currentColor;
        }
      }
    }

    .ua-collapse__toggler--close {
      transform: rotate(0);
    }

    .ua-collapse__content {
      overflow: hidden;
      transition: max-height 0.33s ease-in, opacity 0.33s ease;

      &.ua-collapse__content--close {
        max-height: 0;
      }

      .ua-collapse__content-inner {
        padding: 10px;

        .ua-collapse__button {
          border: none;
          background-color: rgba(255, 255, 255, 0.4);
          padding: 12px 18px;
          font-size: 14px;
          line-height: 16px;
          text-align: center;
          color: $white;
          border-radius: 4px;
          min-width: 175px;
          margin-bottom: 3px;
          font-family: $font-family-sans-serif;
        }
      }

      .ua-collapse__list {
        list-style-type: none;
        padding: 0;
        margin: 7px 0 0;
        font-family: $font-family-sans-serif;
      }

      .collapse-item {
        display: flex;
        align-items: center;
        padding: 5px 0;
        color: $white;
        font-size: 12px;
        line-height: 13px;
        outline: none !important;

        .dropdown-toggle {
          opacity: 0;
          padding: 0 3px;
          background: none;
          border: none;
          margin-left: auto;
        }

        &:hover .dropdown-toggle {
          opacity: 1;
        }

        & .board-item__menu > .dropdown-menu {
          opacity: 1;
        }

        .collapse-item__drag-btn {
          color: $white;
        }

        .collapse-item__link {
          display: flex;
          align-items: center;
          text-decoration: none;
          width: 100%;

          .collapse-item__avatar {
            width: 20px;
            height: 20px;
            flex: 0 0 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(255, 255, 255, 0.4);
            font-size: 10px;
            line-height: 21px;
            color: $white;
            margin-left: 15px;
            margin-right: 10px;
            border-radius: 50%;
          }

          .collapse-item__name {
            margin-bottom: 0;
            font-size: 12px;
            line-height: 13px;
            color: $white;
            font-weight: bold;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 110px;
          }
        }
      }
    }
  }

  &.sidebar--show {
    width: 260px;
  }

  &.sidebar--disable {
    pointer-events: none;
  }

  @media (min-width: 1025px) {
    &.sidebar--closed:not(:hover) {
      width: 70px;
      flex: 0 0 70px;

      .help-link {
        padding: 0;

        span {
          opacity: 0;
          transform: translate3d(-25px, 0, 0);
        }
      }

      .logo {
        padding-left: 0;
      }

      .logo-big {
        z-index: 0;
        opacity: 0;
      }

      .logo-small {
        z-index: 1;
        opacity: 1;
      }

      .sidebar__menu {
        padding: 35px 15px;

        .menu-item {
          margin-bottom: 10px;

          &.menu-item--search {
            margin-bottom: 25px;
          }

          .sidebar-link {
            padding: 5px;
            span {
              opacity: 0;
              transform: translate3d(-25px, 0, 0);
            }
          }
        }

        .menu-item__expanded {
          opacity: 0;
        }

        .menu-item__truncate {
          opacity: 1;
        }
      }

      .ua-collapse__toggler {
        opacity: 0;
      }

      .ua-collapse__content {
        max-height: 0;
        opacity: 0;
      }

      ~ .layout__header {
        left: 70px;
        width: calc(100% - 70px);
      }

      ~ .layout__content {
        padding-left: 70px;
      }
    }
  }
}

.sidebar__header-menu-wrap {
  overflow-x: hidden;
  overflow-y: auto;
  flex: 1;
}

.sidebar__header {
  padding: 20px 20px 19px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);

  .logo {
    position: relative;
    text-align: left;
    padding-left: 11px;
    transition: all 0.33s ease-in;
  }

  .logo-bit {
    z-index: 1;
  }

  .logo-small {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 0;
  }
}

.sidebar__logo {
  position: relative;
}

.dashboard-account-switch {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 16px;

  .custom-control {
    display: flex;
    align-items: center;
    min-height: auto;
  }

  .custom-control-input {
    display: none;
  }

  .custom-switch {
    .custom-control-label {
      cursor: pointer;
      &::before {
        top: 0;
      }

      &::after {
        top: 2px;
      }
    }
  }
}

@media (min-width: 1200px) {
  .layout-dashboard {
    .layout__header {
      .search {
        display: block;
      }
    }

    .layout__main {
      .search:not(.toolbar-search) {
        display: none;
      }
    }
  }
}

@media (max-width: 1199px) {
  .layout-dashboard {
    .layout__header {
      .profile-dropdown {
        margin-left: auto;
      }
    }

    .layout__main-controls {
      display: flex;
      justify-content: flex-end;
    }
  }

  .layout__header-controls {
    display: none;
  }
}

@media (max-width: 1024px) {
  .layout__header {
    position: sticky;
    width: 100%;
    background-color: $bg-blue;
    z-index: 10;
    top: 0;
    left: 0;
  }

  .layout-dashboard {
    display: block;
    position: relative;

    .sidebar,
    .navigation-toggler {
      order: 1;
    }

    .sidebar {
      position: fixed;
      right: 0;
      top: 0;
      left: auto;
      width: 260px;
      transform: translate3d(260px, 0, 0);
      transition: transform 0.33s ease-in;
      z-index: 1000;
    }

    .layout__header {
      transition: transform 0.33s ease-in;
      transform: translate3d(0, 0, 0);
      .page-title {
        margin-left: 0;
      }
    }

    .layout__content {
      width: 100%;
      transition: all 0.33s ease-in;
      padding: 0;
    }

    .ua-bottom-menu {
      transition: transform 0.33s ease-in;
      transform: translate3d(0, 0, 0);
    }

    &.layout-dashboard--sidebar-open {
      .sidebar {
        transform: unset;
      }

      .layout__content,
      .layout__header,
      .ua-bottom-menu {
        transform: translate3d(-260px, 0, 0);
      }
    }

    .profile-dropdown {
      margin-right: 16px;
    }
  }
}

@media (max-width: 767.98px) {
  .layout-dashboard {
    .layout__header {
      height: auto;
      padding: 20px;

      .profile-dropdown {
        display: none;
      }

      .navigation-toggler {
        margin-left: auto;
      }
    }

    .sidebar__header {
      padding: 34px 20px 13px;
    }

    .layout__sidebar {
      .sidebar__profile {
        padding: 10px 5px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      }

      .profile-dropdown {
        display: flex;
        flex-direction: column;
        margin-right: 0;

        .dropdown-toggle {
          width: 100%;
          color: $white;

          .ua-dropdown__button-content {
            margin-right: auto;
          }

          .ua-dropdown__caret {
            margin-left: auto;
          }

          &:hover {
            background-color: rgba(255, 255, 255, 0.15) !important;
          }
        }

        .profile-dropdown__account-accordion {
          color: $white;

          svg {
            path {
              fill: currentColor;
            }
          }

          .arrow-icon {
            path {
              fill: none;
              stroke: currentColor;
            }
          }
        }

        #expanded-account-list {
          > .selected-account {
            background-color: rgba(255, 255, 255, 0.4);
            color: $white;
          }

          .account-switcher__name {
            color: $white;
          }
        }

        .dropdown-menu {
          position: static !important;
          transform: none !important;
          background-color: transparent;
          border: none;
          max-height: 0;
          overflow: hidden;
          transition: all 0.33s ease;
          display: block;
          padding: 0;

          .dropdown-item {
            color: $white;
            border-radius: 4px;
            padding-left: 22px;

            svg {
              path {
                fill: currentColor;
              }
            }

            &:hover {
              background-color: rgba(255, 255, 255, 0.15);
            }
          }

          .dropdown-divider {
            display: none;
          }
        }

        &.show {
          .dropdown-menu {
            max-height: 1000px;
            padding: 5px 0;
          }
        }
      }

      .ua-collapse .ua-collapse__content .collapse-item .dropdown-toggle {
        display: block;
      }
    }

    .layout__main {
      padding: 20px 15px 100px;
    }
  }
}

div.sidebar-loader {
  height: calc(100vh - 320px);
  color: white;
}
