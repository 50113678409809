@import 'assets/scss/variables';

.billing-spinner-container {
  display: flex;
  justify-content: center;
}

.billing-spinner {
  color: $primary;
}

.billing {
  position: relative;

  @media screen and (max-width: 768px) {
    padding-top: 70px;
  }
}

.billing__card {
  padding: 20px 30px;
  border-radius: 4px;
  max-width: 680px;
  border: 1px solid #e7e7e9;
  color: $gray;

  &:not(:last-of-type) {
    margin-bottom: 20px;
  }
}

.billing__title {
  font-weight: 300;
  font-size: 28px;
  line-height: 30px;
  color: $dark-gray;
  margin-bottom: 0;
}

.billing__subtitle {
  font-weight: 300;
  font-size: 16px;
  color: $dark-gray;
  margin-bottom: 0;
}
