@import '../../assets/scss/variables';

.form-auth {
  &.form-auth--login {
    .form__controls-row {
      margin: 11px 0;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    .form__submit {
      margin-right: 0;
    }

    .form__link {
      margin-top: 20px;
    }

    .form__link--forgot {
      margin-top: 0;
      font-size: 12px;
      font-weight: 500;
      line-height: 21px;
      color: $gray;
      text-transform: none;
    }

    .ua-checkbox {
      .ua-checkbox__icon {
        width: 19px;
        padding-left: 3px;
      }
    }

    .ua-input {
      margin-bottom: 21px;
    }
  }
}
