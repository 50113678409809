@import 'src/assets/scss/variables';

.boards-modal-duplicate-tags {
  .modal-body {
    padding-bottom: 24px;
  }

  .ua-modal__footer-button.ok-button {
    width: 375px;
  }

  .sub-title {
    text-align: center;
    margin: 0 auto;
    max-width: 420px;
    font-size: 16px;
  }

  .help-text {
    color: $dark-blue;
    text-align: center;
    margin: 0 auto 8px;
    max-width: 420px;
    font-size: 14px;
  }

  .ua-modal__title {
    font-size: 22px;
    margin-bottom: 8px;
  }

  .tab-container {
    display: flex;
    margin-bottom: 18px;

    @media (max-width: 1024px) {
      flex-direction: column;
    }
  }

  .tab-column {
    display: flex;
    flex-direction: column;
    max-height: 278px;
    padding: 0 24px;

    @media (max-width: 1024px) {
      padding: 0 12px;
    }
  }

  .projects-title {
    flex: 5;
  }

  .tags-title {
    flex: 6;

    @media (max-width: 1024px) {
      display: none;
    }
  }

  .tab-projects {
    flex: 5;

    @media (max-width: 1024px) {
      flex-direction: row;
      gap: 24px;
      margin-bottom: 24px;
    }
  }

  .tab-tags {
    flex: 6;
    gap: 12px;
  }

  .tab-link {
    padding: 0;
    margin: 0;
    border: none;
    background: none;
    color: #888;
    text-align: start;
    font-size: 14px;
    display: flex;
    align-items: center;

    .icon-position {
      border: 1px solid #888;
      border-radius: 50%;
      height: 24px;
      width: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 18px;
    }

    &.active {
      color: #87CB16;

      .icon-position {
        border-color: #87CB16;
      }
    }
  }

  .stepper-line {
    display: block;
    border-color: #E7E7E9;
    border-left-style: solid;
    border-left-width: 1px;
    min-height: 24px;
    margin-left: 12px;

    @media (max-width: 1024px) {
      display: none;
    }
  }

  .tag-border-left {
    border-color: #E7E7E9;
    border-left-style: solid;
    border-left-width: 1px;

    @media (max-width: 1024px) {
      border-left-width: 0;
    }
  }

  .button-container {
    max-width: 375px;
    row-gap: 16px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;

    .button-row {
      display: flex;
      column-gap: 24px;
    }
  }

  .button-navigate {
    flex: 1;

    &:disabled {
      border: 1px solid #E7E7E9;
      color: #E7E7E9;
    }
  }
}

.tag-edit-container {
  display: flex;

  .tag-edit-content {
    flex: 1;
    border-radius: 8px;
    padding: 8px 16px;
    height: 40px;

    &.tag--dark {
      color: $black;
    }

    &.tag--light {
      color: $white;
    }
  }

  .tag-edit-input {
    background: none;
    border: none;
    outline: none;
    padding: 0;
    width: 100%;
    color: inherit;
    border-bottom-style: solid;
    border-bottom-width: 1px;
  }

  .tag--dark > .tag-edit-input {
    border-bottom-color: $black;
  }

  .tag--light > .tag-edit-input {
    border-bottom-color: $white;
  }

  .tag-edit-button {
    border: none;
    background: none;
    margin-left: 10px;

    & > svg {
      width: 21px;
      height: 21px;
    }
  }
}
