.status__dropdown {
  .dropdown-toggle {
    padding: 0;
    opacity: 1;

    & .tag {
      margin: 0;
    }
  }

  .dropdown-menu {
    max-height: 40vh;
    overflow-y: scroll;
  }
}
