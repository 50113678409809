@import '../variables';

.Toastify {
  .Toastify__toast-container {
    padding: 0;
    width: auto;

    &.Toastify__toast-container--top-right {
      top: 90px;
      right: 30px;
    }

    @media (max-width: 520px) {
      width: calc(100% - 30px);

      &.Toastify__toast-container--top-right {
        right: 15px;
        left: auto;
      }
    }
  }

  .Toastify__toast {
    min-height: 0;
    padding: 4px 10px 4px 15px;

    &.Toastify__toast--success {
      background: $success;

      .Toastify__toast-body {
        width: 100%;
        padding: 0;

        @media (max-width: 520px) {
          width: auto;
        }

        .notification {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
        }

        .notification__text,
        .notification__timer {
          font-size: 14px;
          line-height: 21px;
          margin-bottom: 0;
          color: $white;
        }

        .notification__spinner {
          padding: 4px;
          margin-left: 22px;
          margin-right: 2px;

          img {
            animation: spin 1s linear infinite;
          }
        }

        .notification__close {
          color: $white;
          background-color: $success;
          border: none;
          padding: 0;
          margin-left: 10px;
          border-radius: 4px;

          &:hover {
            background-color: darken($success, 5%);
          }

          svg {
            width: 30px;
            height: 30px;

            path {
              fill: currentColor;
            }
          }
        }

        .notification__actions {
          margin-left: 13px;
          display: flex;
          align-items: center;

          @media (max-width: 520px) {
            margin-top: 8px;
            margin-bottom: 8px;
            width: 100%;
            justify-content: center;
          }
        }

        .notification__undo {
          display: flex;
          align-items: center;
          justify-content: center;
          color: $white;
          font-size: 14px;
          line-height: 16px;
          text-align: center;
          padding: 6px;
          width: 80px;
          background-color: $success;
          border: 1px solid $white;
          border-radius: 4px;

          &:hover {
            background-color: darken($success, 5%);
          }
        }

        .notification__icon {
          margin-right: 10px;
        }
      }
    }
  }

  & button {
    margin-left: 8px;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
