@import '../../../assets/scss/variables';

.ua-editable-field__content {
  cursor: pointer;
}

.ua-editable-field__block-editing {
  cursor: auto;
}

.ua-editable-field__input {
  width: 100%;
  font-size: 14px;
  line-height: 21px;
  color: $dark-gray;
  border: 1px solid $light-gray;
  border-radius: 4px;
  padding: 8px 13px;
  -webkit-appearance: none;

  &:focus {
    outline: none;
    border-color: $secondary;
  }

  &.textarea {
    padding: 10px;
    overflow-y: visible;
    resize: none;
  }
}

.ua-editable-field {
  .ua-editable-field__input {
    display: none;
  }

  &.ua-editable-field--editing {
    .ua-editable-field__input {
      display: block;
    }

    .ua-editable-field__content {
      display: none;
    }
  }
}
