@import 'assets/scss/variables';

.project-filter {
  width: 100%;
  margin-bottom: 20px;

  & .project-filter-select__single-value {
    font-size: 14px;
    background: $light-gray;
    border-radius: 4px;
    padding: 2px 6px;
  }
}

.project-filter__header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: $dark-gray;
}

.project-filter__icon {
  margin-right: 10px;

  svg {
    width: 20px;
    height: 20px;

    path {
      fill: currentColor;
    }
  }
}

.project-filter__name {
  font-size: 16px;
  line-height: 17px;
}

.project-filter__toggle {
  margin-left: auto;
  display: flex;
  align-items: center;
}



.project-filter-select-btn {
  background: none;
  border: 1px solid #e7e7e9;
  border-radius: 4px;
  outline: none;
  width: 100%;
  height: 38px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #333;
  padding: 0 0 0 8px;

  & > svg {
    width: 28px;
    height: 28px;
  }
}

.filter-members-selected-item {
  width: 100%;
  position: relative;

  p {
    flex: 1;
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.filter-members-selected__type {
  position: absolute;
  top: -10px;
  left: 20px;
  border-radius: 50%;
  background: white;

  svg {
    width: 20px;
    height: 20px;
    fill: #888;
  }
}

.project-filter-selected-items {
  .tag {
    font-size: 14px;
    padding: 2px 6px;
    line-height: 22px;

    &.tag-delete-btn {
      padding-right: 27px;
    }

    & > svg {
      fill: white;
      width: 14px;
      height: 14px;
      margin-bottom: 1px;
      margin-right: 4px;
    }

    & > .delete-btn svg {
      height: 14px;
      width: 14px;
      margin-bottom: 1px;
    }
  }
}

.project-filter-tabs {
  .nav-item {
    flex: 1;
    text-align: center;
    color: #888;

    &.active {
      color: #447df7;
    }
  }

  & ~ .tab-content {
    margin-top: 16px;

    .tab-pane {
      display: none;
      flex-direction: column;
      max-height: 290px;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 0 8px;

      &.active {
        display: flex;
        gap: 12px;
      }

      .tag {
        height: 35px;
        width: 100%;
        display: block;
        font-size: 16px;
        line-height: 35px;
        cursor: pointer;

        &:hover {
          transform: translate(1px, 1px);
        }
      }

      .project-filter-member__option {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 2px;
        border-radius: 4px;

        &:hover {
          background: rgba(0, 0, 0, 0.08);
        }

        & > .user-avatar {
          margin-right: 12px;
        }

        & > p {
          flex: 1;
          margin: 0;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
  }
}


