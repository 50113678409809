.registration-form {
  & .form__submit {
    width: 100%;
    margin-bottom: 20px;
  }

  @media (max-width: 425px) {
    margin: 0;
    padding-bottom: 35px;
  }
}

.registration-form__terms {
  margin-top: 20px;
  margin-bottom: 18px;
}

.registration-form__terms {
  & .ua-checkbox__label {
    align-items: flex-start;
  }

  & .form__help-text {
    margin-bottom: 0;
    text-align: left;
    line-height: 1.2 !important;
  }
}

.registration-form__terms-link {
  line-height: 1.4;
  font-weight: 400;
}
