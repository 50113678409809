@import '../../../assets/scss/variables';

.kanban-card-container {
  padding: 5px 0;
}

.kanban-card {
  background-color: $white;
  border: 1px solid $light-gray;
  border-radius: 4px;
  padding: 10px;
}
