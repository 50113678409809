@import '../../assets/scss/variables';

.reset-password {
  .btn-light {
    width: 110px;
    background-color: transparent;
    border-color: transparent;
    color: $gray;

    &:hover {
      color: $dark-gray;
      background-color: #e2e6ea;
      border-color: #dae0e5;
    }
  }

  .btn-submit {
    width: 170px;
  }

  .form__controls-row {
    flex-direction: row;
    justify-content: center;
  }

  @media (max-width: 575.98px) {
    .btn-light {
      width: 90px;
    }

    .btn-submit {
      width: 140px;
      padding: 11px 10px;
    }
  }
}
