@import '../../../assets/scss/variables.scss';

.ua-confirm {
  position: relative;
  width: 100%;
}

.ua-confirm__input {
  width: 100%;
  height: 40px;
  font-size: 14px;
  color: $dark-gray;
  border: 1px solid $light-gray;
  border-radius: 4px;
  padding: 8px 68px 8px 13px;
  -webkit-appearance: none;
  font-family: $font-family-sans-serif;

  &::placeholder {
    font-weight: normal;
    color: $secondary;
  }

  &.ua-input__control--with-icon {
    padding-right: 48px;
  }

  &.ua-input__control--with-prepend-icon {
    padding-left: 40px;
  }

  &:focus {
    outline: none;
    border-color: $secondary;
  }
}

.ua-confirm__btn {
  position: absolute;
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  top: 50%;
  transform: translateY(-50%);

  svg {
    width: 22px;
    height: 22px;
  }

  &.--submit {
    right: 38px;

    svg path {
      fill: #87CB16;
    }
  }

  &.--cancel {
    right: 10px;
  }
}
