@import 'assets/scss/variables';

.user-avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;

  &.user-avatar--green {
    background-color: $success;
  }

  &.user-avatar--blue {
    background-color: $info;
  }

  &.user-avatar--dark-blue {
    background-color: $primary;
  }

  &.user-avatar--orange {
    background-color: $warning;
  }
}

.user-avatar__image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.user-avatar__loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: $primary;
}

.user-avatar__name {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  line-height: 21px;
  color: $white;
}
