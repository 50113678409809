@import 'assets/scss/variables';

.ua-colorpicker,
.ua-colorpicker__container {
  position: relative;
  display: flex;
}

.ua-colorpicker {
  flex-direction: column;
}

.ua-colorpicker__container {
  margin-top: 10px;
  margin-bottom: 8px;
}

.ua-colorpicker__saturation {
  position: relative;
  flex: 1;
  border-radius: 2px;
  margin-right: 13px;
  overflow: hidden;
}

.ua-colorpicker__hue {
  position: relative;
  width: 10px;
  height: 72px;
  border-radius: 2px;
  margin-right: 13px;
}

.ua-colorpicker__hue-pointer {
  width: 9px;
  height: 5px;
  background-color: $light-gray;
  border-radius: 1px;
}

.ua-colorpicker__predefined {
  display: flex;
  width: 32px;
  flex-wrap: wrap;
  column-gap: 4px;

  .color {
    width: 14px;
    height: 14px;

    div {
      height: 100%;
      border: 1px solid;
      border-radius: 2px;

      &:hover {
        border-color: $gray !important;
        cursor: pointer;
      }
    }
  }
}

.ua-colorpicker__editable-input {
  position: relative;

  div {
    border-radius: 4px;
    border: 1px solid $light-gray;
  }

  input {
    padding-left: 11px;
    padding-right: 60px;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    height: 25px;
    color: $gray;
    width: 100%;
    border: none;
    outline: none !important;
  }
}

.ua-colorpicker__clear {
  width: 65px;
  height: 25px;
  background-color: $bg-blue;
  border-radius: 4px;
  border: none;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  color: $gray;
  position: absolute;
  right: 1px;
  top: 1px;
}

.ua-colorpicker--error {
  .ua-colorpicker__editable-input {
    div {
      border-color: $danger;
    }
  }
}

.ua-colorpicker--valid {
  .ua-colorpicker__editable-input {
    div {
      border-color: $success;
    }
  }
}
