.filter-members-selected-item {
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  & .filter-members-selected-item-delete {
    padding: 0;
    border: none;
    background: none;
    margin-left: auto;
  }

  & .user-avatar {
    margin-right: 8px;
  }
}
