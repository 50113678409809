@import '../../../assets/scss/variables';

.kpi-card {
  background-color: $white;
  border: 1px solid $light-gray;
  border-radius: 4px;
  width: 240px;
  padding: 22px 0;
  display: flex;
  align-items: center;
  position: relative;

  .kpi-card__info-count {
    font-size: 28px;
    line-height: 30px;
    font-weight: 300;
  }

  .kpi-card__settings {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    padding: 0;
    display: none;
    width: 12px;
    height: 12px;
  }

  &.active {
    .kpi-card__info-count {
      color: $primary;
    }
  }

  &.completed {
    .kpi-card__info-count {
      color: $success;
    }
  }

  &.due_soon {
    .kpi-card__info-count {
      color: $warning;
    }
  }

  &.overdue {
    .kpi-card__info-count {
      color: $danger;
    }
  }

  &:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.7);

    .kpi-card__settings {
      display: flex;
    }
  }
}

.kpi-card__icon {
  width: 40px;
  height: 40px;
  margin-right: 30px;
  margin-left: 40px;
}

.kpi-card__info-label {
  font-size: 16px;
  line-height: 24px;
  color: $gray;
}

@media (max-width: 991.98px) {
  .kpi-card {
    width: 100%;
  }

  .kpi-card__icon {
    margin-left: 15px;
    margin-right: 10px;
    width: 32px;
    height: 32px;
  }

  .kpi-card__info-count {
    font-size: 16px;
    line-height: 17px;
  }

  .kpi-card__info-label {
    font-size: 14px;
    line-height: 21px;
  }
}

@media (max-width: 1330px) {
  .kpi-card {
    width: 100%;
    padding: 12px 0;
  }
}

@media (max-width: 767.98px) {
  .kpi-card {
    padding: 12px 0;
  }
}
