@import '../../../assets/scss/variables';

.due-date {
  display: flex;
  align-items: center;
  font-size: 10px;
  line-height: 13px;
  color: $dark-gray;

  svg {
    margin-right: 5px;
  }
}
