@import '../../../assets/scss/variables';

.project-widget__header {
  display: flex;
  align-items: center;
  color: $dark-gray;
}

.project-widget__header-icon {
  svg {
    width: 20px;
    height: 20px;
    margin-right: 10px;

    path {
      fill: currentColor;
    }
  }
}

.project-widget__header-title {
  font-size: 14px;
  line-height: 21px;
}

.project-widget__header-action {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.project-widget__content {
  padding-top: 9px;
  padding-left: 30px;
}

.project-widget__list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
}

.project-widget__item-wrapper {
  padding-left: 5px;
  padding-right: 5px;
  flex: 1 1 auto;
  max-width: 260px;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
}

@media (max-width: 767.98px) {
  .project-widget__item-wrapper {
    max-width: 100%;
    flex: 0 0 100%;
  }
}
