@import '../../../assets/scss/variables';

.kanban-column__body {
  display: flex;
  flex-direction: column;
  padding: 5px 10px;

  .status-list__dropdown {
    height: auto;
    width: 100%;

    .btn {
      height: auto;
      width: 100%;
    }

    .ua-dropdown__button-content {
      width: 100%;
    }
  }

  > .tag-list__dropdown {
    .dropdown-menu {
      min-width: 360px;
    }

    .btn {
      padding: 0;
      width: 100%;
    }

    .ua-dropdown__button-content {
      width: 100%;
    }
  }
}

.kanban-column__footer {
  display: flex;
  justify-content: center;
  padding: 0 10px 10px;
}

.kanban-column {
  .kanban-column__header {
    display: flex;
    padding: 10px 13px 3px 20px;
    justify-content: space-between;
  }

  &.kanban-column--create {
    > div {
      padding: 0;
      border: none;
      background: none;
    }

    .kanban-column__header,
    .kanban-column__footer {
      display: none;
    }

    .kanban-column__body {
      padding: 0;
    }
  }

  &.kanban-column--hidden {
    display: none;
  }
}

.kanban-column-inner {
  display: flex;
  flex-direction: column;
  border: 1px solid $light-gray;
  background-color: #f9f9f9;
  border-radius: 4px;
  width: 300px;
}

.kanban-column__header-title {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  font-size: 12px;
  line-height: 13px;
}

.kanban-column__add-project-button {
  font-size: 10px;
  line-height: 20px;
  font-weight: 300;
  color: $gray;
  display: flex;
  align-items: center;
  border: none;
  background: none;

  svg {
    margin-right: 5px;
    width: 10px;
    height: 10px;

    path {
      fill: currentColor;
    }
  }
}

.kanban-column__header-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  border-radius: 4px;

  .dropdown-toggle {
    padding: 4px;
  }

  &:hover {
    background-color: rgba($bg-blue, 5%);
  }
}
