@import '../../assets/scss/variables';

.board-setting-card {
  width: 100%;
}

.board-setting-card__header {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.board-setting-card__title-line {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.board-setting-card__title {
  font-size: 16px;
  line-height: 17px;
  color: $dark-gray;
  margin-right: auto;
  font-weight: 400;
  margin-bottom: 0;
}

.board-setting-card__header-tag {
  font-size: 16px;
  line-height: 17px;
  color: $dark-gray;
}

.board-setting-card__close-button {
  color: $dark-gray;
  margin-left: 15px;
  padding: 0;
  border: none;
  background-color: transparent;
  border-radius: 4px;

  svg {
    path {
      fill: currentColor;
    }
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.board-setting-card__custom-view-dropdown {
  .dropdown-toggle {
    font-size: 12px;
    line-height: 17px;
    color: $primary;
    padding: 0;
  }
}

.board-setting-card__actions {
  margin-top: 20px;

  button {
    &:first-child {
      margin-right: 10px;
    }

    &:last-child {
      margin-left: 10px;
    }
  }

  .board-setting-card__custom-view-dropdown {
    button {
      margin: 0;
    }
  }
}

.board-setting-card__actions-wrapper {
  display: flex;
  margin-bottom: 20px;
}

.board-setting-card__clear-button {
  width: 170px;
  font-size: 14px;
  line-height: 16px;
  border-radius: 4px;
  border: 2px solid $primary;
  color: $primary;
  background-color: transparent;
  padding: 12px;
  transition: 0.15s ease;

  &:hover {
    color: $white;
    background-color: $primary;
  }
}

.board-setting-card__save-button {
  width: 170px;
  font-size: 14px;
  line-height: 16px;
  border: 2px solid $success;
  color: $white;
  background-color: $success;
  padding: 12px;
  border-radius: 4px;
  transition: 0.15s ease;

  &:hover {
    border-color: darken($success, 5%);
    background-color: darken($success, 5%);
  }
}

.board-setting-card__body {
  padding-left: 20px;
  padding-right: 20px;
}
