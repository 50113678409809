@import '../../../assets/scss/variables';

.ua-checkbox__icon {
  display: flex;
  align-items: center;
  width: 16px;
  margin-right: 9px;
}

.ua-checkbox__label {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 500;
  line-height: 21px;
  color: $gray;
}

.ua-checkbox__control {
  opacity: 0;
  position: absolute;
  width: 1px;
  height: 1px;

  &:checked {
    ~ .ua-checkbox__label {
      .ua-checkbox__icon {
        color: $primary;
      }
    }
  }
}
