.user-name-edit {
  display: flex;
  align-items: center;

  * + * {
    margin-left: 8px;
  }

  & .ua-input {
    margin-bottom: 0;
  }

  & .ua-input__control {
    height: 20px;
    font-size: 10px;
    padding: 2px 5px;
    line-height: 1.5;
  }
  & .ua-input__control.ua-input__control--with-icon {
    padding-right: 30px;
  }
  & .ua-input__icon {
    height: 100%;
    right: 0;
  }

  .close-btn {
    border: none;
    background: none;
    padding: 0;
  }
}

.edit-button {
  display: none;
  padding: 0 8px;
  cursor: pointer;
}

.user-name {
  margin-bottom: 0;
  &:hover > .edit-button {
    display: inline-block;
  }
}
